import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_URL,
  prepareHeaders: (headers) => {
    headers.set("Access-Control-Allow-Origin", "*");
    // headers.set("Access-Control-Allow-Methods", "POST, PUT, GET, DELETE");
    const token = localStorage.getItem("jwt");
    if (token) {
      headers.set("authorization", `Bearer ${token}`);
    }
    return headers;
  },
  mode: "cors",
});

export const apis = createApi({
  reducerPath: "apis",
  baseQuery,
  endpoints: () => ({}),
  tagTypes: ["Auth", "Countries", "URLs", "Orders", "Products", "Categories", "Companies", "CompanyFiles", "DigitalKeys", "Resellers", "Suppliers", "Notifications", "StockBacks", "EmailTemplates", "EmailTemplateTypes", "BDM", "ReminderTypes", "Reminders", "DiscountedProducts", "Configs", "CronScheduler"],
});
