/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { styled } from "@mui/material/styles";

import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";

import { convertToHTML } from "draft-convert";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { companiesSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import Alert from "components/CustomAlert";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

// rtk queries
import { useGetAllStatesByCountryIdQuery } from "services/countries";

import { useUpdateCompanyMutation } from "services/companies";

import { globalCountry } from "store/entities/countries";

import { formatDate } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditCompany({ details }) {
  const isEdit = true;

  const selectedCountry = useSelector(globalCountry);

  const [updateCompany, { isLoading: isUpdateCompanyLoading }] = useUpdateCompanyMutation();

  const {
    data: statesByCountryId = [],
    isLoading: isLoadingStates,
    isSuccess: isSuccessStates,
  } = useGetAllStatesByCountryIdQuery(selectedCountry.id);

  const initialFormState = {
    is_credit_customer: 0,
    name: "",
    trading_as: "",
    proprietor: "",
    gst_no: "",
    reg_no: "",
    is_public_company: 0,
    website: "",
    no_of_employees: "",
    years_in_business: "",
    type: "",
    invoice_email: "",
    address: "",
    billing_address: "",
    country_id: "",
    postcode: "",
    biling_postcode: "",
    main_phone: "",
    email: "",
    account_payable_contact: "",
    account_payable_phone: "",
    account_payable_fax: "",
    account_payable_email: "",
    booking_officer: "",
    booking_officer_email: "",
    booking_officer_phone: "",
    is_part_ship: 0,
    expected_monthly_purchase: "",
    credit_limit: "",
    year_end_date: "",
    is_audited_on_year_end: 0,
    enable_free_shipping: 0
  };

  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(companiesSchema),
    defaultValues: initialFormState,
  });

  const isCreditCustomer = watch("is_credit_customer");

  const [openCompanyEdit, setOpenCompanyEdit] = useState(false);

  const handleClickOpen = () => {
    setOpenCompanyEdit(true);
  };
  const handleClose = () => {
    setOpenCompanyEdit(false);
  };

  const onSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    data.country_id = details.country_id;
    
    delete data.assigned_bdms;

    delete data.id;
    delete data.company_note;
    delete data.credit_application_status;
    delete data.credit_note;
    delete data.status;

    let modifiedData = data;

    modifiedData.year_end_date = formatDate(data.year_end_date);
    modifiedData.is_audited_on_year_end = data.is_audited_on_year_end;
    modifiedData.is_part_ship = data.is_part_ship;
    modifiedData.enable_free_shipping = data.enable_free_shipping ? 1 : 0;
    modifiedData.is_credit_customer = data.is_credit_customer;
    modifiedData.is_public_company = data.is_public_company ? 1 : 0;

    try {
      updateCompany({ company_id: details.id, data: modifiedData }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Succefully Updated!");
          setOpenCompanyEdit(false);
        } else {
          Notify.failure("Something went wrong, Please try again!");
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    reset(details);
  }, [details]);

  return (
    <>
      <Tooltip title="Edit company" placement="top" sx={{ ml: 1 }}>
        <IconButton aria-label="view" size="small" onClick={() => handleClickOpen()}>
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={openCompanyEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Company
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="email"
                  control={control}
                  labelText="Email *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="name"
                  control={control}
                  labelText="Name *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="address"
                  control={control}
                  labelText="Address *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="postcode"
                  control={control}
                  labelText="Postcode *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="billing_address"
                  control={control}
                  labelText="Billing Address *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="biling_postcode"
                  control={control}
                  labelText="Biling Postcode *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="website"
                  control={control}
                  labelText="Website "
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="main_phone"
                  control={control}
                  labelText="Main Phone *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="type"
                  control={control}
                  labelText="Type *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="gst_no"
                  control={control}
                  labelText="GST_No *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="reg_no"
                  control={control}
                  labelText="Registration No *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="no_of_employees"
                  control={control}
                  labelText="Number of employees *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="years_in_business"
                  control={control}
                  labelText="Years in business *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledDateTime
                  label="Year end date"
                  name="year_end_date"
                  control={control}
                  inputProps={{
                    placeholder: "Pick a Date",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="expected_monthly_purchase"
                  control={control}
                  labelText="Expected monthly purchase "
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="trading_as"
                  control={control}
                  labelText="Trading as"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="proprietor"
                  control={control}
                  labelText="Proprietor"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="is_public_company"
                  control={control}
                  label="Is public company?"
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="is_audited_on_year_end"
                  control={control}
                  label="Is audited on year end?"
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox name="is_part_ship" control={control} label="Is part ship?" />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox name="enable_free_shipping" control={control} label="Is free shipping?" />
              </GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="account_payable_contact"
                  control={control}
                  labelText="Account payable contact"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="account_payable_phone"
                  control={control}
                  labelText="Account payable phone *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="account_payable_fax"
                  control={control}
                  labelText="Account payable fax "
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="account_payable_email"
                  control={control}
                  labelText="Account payable email "
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="booking_officer"
                  control={control}
                  labelText="Booking officer"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="booking_officer_email"
                  control={control}
                  labelText="Booking officer email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="booking_officer_phone"
                  control={control}
                  labelText="Booking officer phone *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  // inputProps={{
                  //   type: "number",
                  // }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="invoice_email"
                  control={control}
                  labelText="Invoice email *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "email",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="invoice_ninja_client_id"
                  control={control}
                  labelText="Invoice ninja client ID *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </GridItem>

              {/* ------------------------- credit customer logic --------------------------------- */}
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="is_credit_customer"
                  control={control}
                  label="Is credit customer?"
                />
              </GridItem>

              {isCreditCustomer ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="credit_limit"
                    control={control}
                    labelText="Credit limit *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    // inputProps={{
                    //   type: "number",
                    // }}
                  />
                </GridItem>
              ) : (
                ""
              )}
              {/* ------------------------- credit customer logic --------------------------------- */}
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update Company
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditCompany;
