/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import axios from 'axios';

import { styled } from "@mui/material/styles";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import MDTypography from "components/MDTypography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { configsSchema } from "../schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import InputAdornment from "@mui/material/InputAdornment";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import Alert from "components/CustomAlert";

// rtk queries
import {
  useEditSchedulerRuleMutation
} from "services/configs";

import {
  useUpdateZohoKeysMutation
} from "services/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function GenerateTokens ({ clientId, secretKey, countryId }) {

  const [openEdit, setOpenEdit] = useState(false);

  const initialFormState = {
    grantToken: "",
  };

  const [updateZohoKeys] = useUpdateZohoKeysMutation();


  const { control, watch, handleSubmit, setValue, reset } = useForm({
    resolver: yupResolver(configsSchema),
    defaultValues: initialFormState,
  });

  useEffect(() => {
    
  }, []);

  const handleClickOpen = () => {
    setOpenEdit(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  const onSubmit = async (data) => {
    // console.log(data);
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    const queryParams = new URLSearchParams(data.grantToken);

    // Extract the 'code' parameter
    const codeValue = queryParams.get('code');

    if(!codeValue) {  
      Notify.failure("Invalid URL!");
      return;
    }

    const postData = {
      code: codeValue, // Pass the 'code' obtained from the URL
      redirect_uri: 'https://managedtek.net/', // Add your redirect_uri here
      client_id: clientId, // Add your client ID here
      client_secret: secretKey, // Add your client secret here
      grant_type: 'authorization_code', // This is typically the grant type when using 'code'
    };

    // Make the POST request to get access and refresh tokens
    axios.post('https://accounts.zoho.com/oauth/v2/token', postData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded', // URL encoded form
        "Access-Control-Allow-Origin": "*"
      }
    })
    .then(response => {
      // Extract access_token and refresh_token from the response
      const { access_token, refresh_token } = response.data;
      console.log(response);
      // if(access_token && refresh_token) {
      //   updateZohoKeys({ data: data, countryId: clientId }).then((response) => {
      //     if (response.data?.status == "success") {
      //       Notify.success("Succefully Updated!");
      //       Loading.remove(500);
      //       handleClose();
      //     } else {
      //       Notify.failure("Something went wrong, Please try again!");
      //     }
      //   });
      // }
    })
    .catch(error => {
      console.error('Error fetching tokens:', error);
    });
  };

  return (
    <>
      <Tooltip title="Generate" placement="top">
        <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
          Generate Auth Tokens
        </MDButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "650px",
          },
        }}
        open={openEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Generate Auth Tokens
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <MDBox sx={{ width: '100%' }}>
            <MDTypography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>
              Please Copy the below URL and paste it in a new tab. On this request, you will be shown with a "user consent page". <br /><br />Upon clicking “Accept”, Zoho will redirect to the given redirect_uri with code and state param. This code value is mandatory to get the access token in the next step and this code is valid for 60 seconds.
              <br />On clicking “Deny”, the server returns an error
            </MDTypography>
            <MDBox sx={{ padding: 2, backgroundColor: '#f9f9f9', borderRadius: '0.375rem', mb: 3, border: '1px solid #e9e9e9' }}>
              <MDTypography variant="p" fontWeight="medium" sx={{ fontStyle: 'italic', overflowX: 'scroll', display: 'block', fontSize: '13px', fontWeight: 500, paddingBottom: '10px' }}>
                https://accounts.zoho.com/oauth/v2/auth?scope=ZohoBooks.accountants.CREATE,ZohoBooks.accountants.READ,ZohoBooks.accountants.UPDATE,ZohoBooks.accountants.DELETE,ZohoInventory.salesreturns.CREATE,ZohoInventory.salesreturns.READ,ZohoInventory.salesreturns.UPDATE,ZohoInventory.salesreturns.DELETE,ZohoInventory.creditnotes.CREATE,ZohoInventory.creditnotes.READ,ZohoInventory.creditnotes.UPDATE,ZohoInventory.creditnotes.DELETE,ZohoInventory.settings.CREATE,ZohoInventory.settings.READ,ZohoInventory.settings.UPDATE,ZohoInventory.settings.DELETE,ZohoInventory.contacts.CREATE,ZohoInventory.contacts.READ,ZohoInventory.contacts.UPDATE,ZohoInventory.contacts.DELETE,ZohoInventory.invoices.CREATE,ZohoInventory.invoices.READ,ZohoInventory.invoices.UPDATE,ZohoInventory.invoices.DELETE,ZohoInventory.customerpayments.CREATE,ZohoInventory.customerpayments.UPDATE,ZohoInventory.customerpayments.DELETE,ZohoInventory.customerpayments.READ,ZohoInventory.salesorders.CREATE,ZohoInventory.salesorders.UPDATE,ZohoInventory.salesorders.DELETE,ZohoInventory.salesorders.READ,ZohoInventory.packages.CREATE&client_id={clientId}&state=ictd&response_type=code&redirect_uri=https://managedtek.net/&access_type=offline
              </MDTypography>
            </MDBox>
            <MDTypography variant="h6" fontWeight="medium" sx={{ mb: 2 }}>
              Copy the redirect_uri and paste it in the below text field (Grant Token)
            </MDTypography>
          </MDBox>
          <form>
            <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <ControlledTextInput
                  name="grantToken"
                  control={control}
                  labelText="Grant Token URL *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
          </form>
          <br />
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Generate Auth Tokens
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default GenerateTokens;
