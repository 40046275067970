/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

import moment from "moment";

import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import MDButton from "components/MDButton";
import Typography from "@mui/material/Typography";
import MDTypography from "components/MDTypography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import MDBadge from "components/MDBadge";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import KeyIcon from "@mui/icons-material/Key";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import RejectNote from "components/RejectNote";
import TrackingLinkDialog from "./TrackingLinkDialog";
import MDBox from "components/MDBox";
import SendIcon from "@mui/icons-material/Send";

import EditPODialog from "./EditPODialog";

import Divider from "@mui/material/Divider";

import Button from "@mui/material/Button";

import DataTable from "examples/Tables/DataTable";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";

import { globalCountry } from "store/entities/countries";

import RefundHistory from "./RefundHistory";
import RefundDialog from "./RefundDialog";

import {
  useLazyGetOrderProductsByOrderIdQuery,
  useChangeOrderProductDeliveryStatusMutation,
  useChangeOrderPaymentStatusMutation,
  useRefundHistoryMutation,
  useLazyGetOrderDetailsByOrderIdQuery,
  useResendOrderItemDeliveredMailMutation,
  useResendOrderPlacedMailMutation,
  useUpdateExpiryDateMutation,
  useLazyGetRefundHistoryQuery,
  useLazyDownloadInvoiceQuery,
} from "services/orders";

import {
  useLazyGetAllDigitalKeysByOrderIdQuery,
  useUpdateDigitalKeyMutation,
} from "services/digitalKeys";

import DigitalKeysUpdateDialog from "./DigitalKeysUpdateDialog";
import EditExpiryDateDialog from "./EditExpiryDateDialog";

import { formatDate } from "utils/helperFunctions";
import { dateFormat, tables } from "utils/constants";

import DetailBlock from "components/DetailBlock";
import DigitalKeys from "./DigitalKeys";
import BankReceipts from "./BankReceipts";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import { resetForwardSection } from "store/entities/notifications";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const OrderDetails = ({ order, id }) => {
  const dispatch = useDispatch();

  const selectedCountry = useSelector(globalCountry);

  const [openOrderDetails, setOpenOrderDetails] = useState(false);

  const [refundHistory, setRefundHistory] = useState([]);

  const [refundDifference, setRefundDifference] = useState(0);

  const [rejectNoteOpen, setRejectNoteOpen] = useState(false);

  const [openTrackingDialog, setOpenTrackingDialog] = useState(false);

  const [dataForTrackingLink, setDataForTrackingLink] = useState({});

  const [fetchedOrderDetails, setFetchedOrderDetails] = useState({});

  const [canceledData, setCanceledData] = useState({});

  const [openDigitalKeysUpdateDialog, setOpenDigitalKeysUpdateDialog] = useState(false);

  const [isOrderProductsPhysical, setIsOrderProductsPhysical] = useState(false);

  const [fetchedDigitalKeys, setFetchedDigitalKeys] = useState([]);

  const [tempDeliveredData, setTempDeliveredData] = useState({});

  const [digitalKeysByProduct, setDigitalKeysByProduct] = useState([]);

  const [openPOEdit, setOpenPOEdit] = useState(false);

  const [triggerRefundHistory] = useLazyGetRefundHistoryQuery();

  const [
    triggerProductsByOrderId,
    {
      data: orderProductByOrderId = [],
      isLoading: isLoadingProductsByOrderId,
      isSuccess: isSuccessProductsByOrderId,
    },
  ] = useLazyGetOrderProductsByOrderIdQuery();

  const [
    triggerOrderDetails,
    {
      data: singleOrderDetails = [],
      isLoading: isOrderDetailsLoading,
      isSuccess: isOrderDetailsSuccess,
    },
  ] = useLazyGetOrderDetailsByOrderIdQuery({
    // pollingInterval: 5000,
  });

  const [
    triggerDigitalKeys,
    {
      data: digitalKeysByProductData = [],
      isLoading: isLoadingDigitalKeysByProduct,
      isSuccess: isSuccessDigitalKeysByProduct,
    },
  ] = useLazyGetAllDigitalKeysByOrderIdQuery();

  // const [
  //   triggerRefundHistory,
  //   { isLoading: isRefundHistoryLoading, isSuccess: isRefundHistorySuccess },
  // ] = useRefundHistoryMutation();

  const [updateDigitalKeys, { isLoading: isUpdatingDigitalKeys }] = useUpdateDigitalKeyMutation();

  const [changeDeliveryStatus, { isLoading }] = useChangeOrderProductDeliveryStatusMutation();

  const [changePaymentStatus, { isLoading: isOrderPaymentStatusesLoading }] =
    useChangeOrderPaymentStatusMutation();

  const [resendDeliveredEmail] = useResendOrderItemDeliveredMailMutation();

  const [resendOrderPlacedEmail] = useResendOrderPlacedMailMutation();

  const [putExpiryDate] = useUpdateExpiryDateMutation();

  const [downloadInvoice] = useLazyDownloadInvoiceQuery();

  const handleClickOpen = () => {
    isLoadingProductsByOrderId &&
      isOrderDetailsLoading &&
      Loading.dots({
        svgColor: "rgba(159, 3, 3, 0.8)",
      });
    setOpenOrderDetails(true);
    triggerProductsByOrderId(order.id);
    triggerOrderDetails(order.id);
    setDataForTrackingLink({});
  };

  const handleClose = () => {
    setOpenOrderDetails(false);
    // dispatch(resetForwardSection());
  };

  // console.log(orderDetails);

  const onDeliveryStatusCanceledSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let orderProductId = canceledData.order_product_id;
    canceledData.note = data.note;
    delete canceledData.order_product_id;

    try {
      changeDeliveryStatus({ order_product_id: orderProductId, data: { ...canceledData } }).then(
        (response) => {
          if (response.data?.status == "success") {
            Notify.success("Status changed!");
            triggerProductsByOrderId(order.id);
          }
        }
      );
    } catch (e) {
    } finally {
      setRejectNoteOpen(false);
      Loading.remove(500);
    }
  };

  useEffect(() => {
    if (fetchedOrderDetails && fetchedOrderDetails.id) {
      if (fetchedOrderDetails.refunded_amount != null) {
        triggerRefundHistory(fetchedOrderDetails.id).then((response) => {
          let total = 0;
          if (response && response.data && response.data.length > 0) {
            response.data.map((element) => {
              total += element.amount;
            });
            setRefundHistory(response.data);
          }
          let remainingAmount = (singleOrderDetails.grandtotal - total).toFixed(2);
          setRefundDifference(remainingAmount);
        });
        Loading.remove(500);
      } else {
        setRefundDifference(singleOrderDetails.grandtotal);
        Loading.remove(500);
      }
    }
    // : Loading.remove(500);
  }, [fetchedOrderDetails]);

  useEffect(() => {
    !isLoadingProductsByOrderId && !isOrderDetailsLoading && Loading.remove(500);
  }, [isLoadingProductsByOrderId, isOrderDetailsLoading]);

  useEffect(() => {
    if (singleOrderDetails && singleOrderDetails.id) setFetchedOrderDetails(singleOrderDetails);
  }, [singleOrderDetails]);

  const handleChangeDigitalProductsDeliveryStatus = async (data) => {
    console.log(data);
    setTempDeliveredData(data);
    const digitalKeys = await triggerDigitalKeys(data.orderId);

    if (digitalKeys && digitalKeys.data) {
      if (digitalKeys.data.length > 0) {
        let filteredDigitalKeys = digitalKeys.data;
        setDigitalKeysByProduct(filteredDigitalKeys);
        if ([...filteredDigitalKeys.map((key) => key.key)].includes("Pending")) {
          setFetchedDigitalKeys(filteredDigitalKeys);
        } else {
          Loading.dots({
            svgColor: "rgba(159, 3, 3, 0.8)",
          });
          try {
            changeDeliveryStatus({
              order_product_id: data.orderProductId,
              data: { ...data },
            }).then((response) => {
              if (response.data?.status == "success") {
                Notify.success("Delivery status changed!");
                triggerProductsByOrderId(fetchedOrderDetails.id);
              }
            });
          } catch (e) {
          } finally {
            Loading.remove(500);
          }
        }
      }
    }

    // Loading.dots({
    //   svgColor: "rgba(159, 3, 3, 0.8)",
    // });
    // try {
    //   changeDeliveryStatus({
    //     order_product_id: original.id,
    //     data: { ...data },
    //   }).then((response) => {
    //     if (response.data?.status == "success") {
    //       Notify.success("Status changed!");
    //       triggerProductsByOrderId(fetchedOrderDetails.id);
    //     }
    //   });
    // } catch (e) {
    // } finally {
    //   Loading.remove(500);
    // }
  };

  useEffect(() => {
    isLoadingDigitalKeysByProduct
      ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)" })
      : Loading.remove(500);
    // Block.dots('#blockDesc', { svgColor: "rgba(159, 3, 3, 0.8)" });

    // Block.remove('#blockDesc', 500);
  }, [isLoadingDigitalKeysByProduct]);

  useEffect(() => {
    fetchedDigitalKeys.length > 0
      ? setOpenDigitalKeysUpdateDialog(true)
      : setOpenDigitalKeysUpdateDialog(false);
    // if(fetchedDigitalKeys.length > 0) setOpenDigitalKeysUpdateDialog(true)
    // console.log(fetchedDigitalKeys);
  }, [fetchedDigitalKeys]);

  const orderProductDetails = useMemo(() => {
    return orderProductByOrderId;
  }, [orderProductByOrderId]);

  async function onDigitalKeysSubmit(data) {
    // console.log(data);
    // console.log(tempDeliveredData);
    setOpenDigitalKeysUpdateDialog(false);
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      updateDigitalKeys(data).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Digital key updated successfully!");
          setTimeout(() => {
            changeDeliveryStatus({
              order_product_id: tempDeliveredData.orderProductId,
              data: { ...tempDeliveredData },
            }).then((response) => {
              if (response.data?.status == "success") {
                Notify.success("Delivery status changed!");
                triggerProductsByOrderId(fetchedOrderDetails.id);

                triggerDigitalKeys(data.orderId).then((digitalKeys) => {
                  if (digitalKeys && digitalKeys.data) {
                    if (digitalKeys.data.length > 0) {
                      let filteredDigitalKeys = digitalKeys.data;
                      setDigitalKeysByProduct(filteredDigitalKeys);
                    }
                  }
                });
              }
            });
          }, 1000);
        }
      });
    } catch (e) {
    } finally {
      setTempDeliveredData({});
      Loading.remove(500);
    }
  }

  const onTrackingLinkSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    let orderProductId = dataForTrackingLink.order_product_id;
    data.note = dataForTrackingLink.note;
    data.orderId = dataForTrackingLink.orderId;
    data.productId = dataForTrackingLink.productId;
    data.status = dataForTrackingLink.status;
    try {
      changeDeliveryStatus({
        order_product_id: orderProductId,
        data: { ...data },
      }).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Status changed!");
          triggerProductsByOrderId(fetchedOrderDetails.id);
        }
      });
    } catch (e) {
    } finally {
      setTempDeliveredData({});
      setOpenTrackingDialog(false);
      Loading.remove(500);
    }
  };

  const resetExpiryDate = async (id) => {
    // orderId
    let data = {};
    data.expiryDate = null;
    try {
      putExpiryDate({ orderProductId: id, data })
        .then((response) => {
          if (response.data?.status == "success") {
            Notify.success("Successfully reset!");
          } else {
            Notify.failure("Something went wrong!");
          }
        })
        .catch((e) => console.log(e));
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (orderProductByOrderId && orderProductByOrderId.length > 0) {
      let isPhysicalProductExist = false;
      for (let product of orderProductByOrderId) {
        if (product.is_digital_good == 0) isPhysicalProductExist = true;
      }
      setIsOrderProductsPhysical(isPhysicalProductExist);
    }
  }, [orderProductByOrderId]);

  return (
    <>
      <Tooltip title="View Order" placement="top">
        <IconButton aria-label="view" size="small" id={`orderId${id}`} onClick={handleClickOpen}>
          <VisibilityIcon color="info" fontSize="inherit" />
        </IconButton>
        {/* <MDButton
          variant="contained"
          size="small"
          color="info"
          sx={{
            ml: 1,
            color: "#fff",
            fontSize: "10px!important",
            fontWeight: "400",
          }}
          onClick={handleClickOpen}
        >
          View
        </MDButton> */}
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "1000px",
          },
        }}
        open={openOrderDetails}
      >
        <DialogTitle color="info" sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Order Details{" "}
          {fetchedOrderDetails.payment_method == "BANK" &&
            fetchedOrderDetails.payment_status == "PENDING" &&
            "(Waiting for payment...)"}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {/* <MDTypography color="info">Order Details</MDTypography> */}
          <MDBox sx={{ textAlign: "right" }}>
            {fetchedOrderDetails.payment_method == "BANK" ? (
              <>
                {fetchedOrderDetails.payment_status == "PENDING" && (
                  <Tooltip title="Mark as Paid" placement="top">
                    <MDButton
                      variant="gradient"
                      size="small"
                      color="success"
                      sx={{
                        ml: 1,
                        color: "#fff",
                        fontSize: "10px!important",
                        fontWeight: "400",
                      }}
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to mark this order as 'Paid'?",
                          "Yes",
                          "No",
                          () => {
                            Loading.dots({
                              svgColor: "rgba(159, 3, 3, 0.8)",
                            });
                            // if(data.paymentIntentId == "") {
                            //     Notify.failure("Payment Intetnt is not found!");
                            // }
                            try {
                              changePaymentStatus({
                                order_id: fetchedOrderDetails.id,
                                status: "PAID",
                                invoiceNinjaAPI: selectedCountry?.invoiceNinjaAPI,
                              }).then((response) => {
                                if (response.data?.status == "success") {
                                  Notify.success("Status changed to 'Paid'!");
                                }
                                // console.log(response);
                              });
                            } catch (e) {
                              console.log(e);
                            } finally {
                              Loading.remove(500);
                            }
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      Mark as Paid
                    </MDButton>
                  </Tooltip>
                )}
                {fetchedOrderDetails.payment_status == "PENDING" && (
                  <Tooltip title="Mark as Refunded" placement="top">
                    <MDButton
                      variant="gradient"
                      size="small"
                      color="secondary"
                      sx={{
                        ml: 1,
                        color: "#fff",
                        fontSize: "10px!important",
                        fontWeight: "400",
                      }}
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to mark this order as 'Refund'?",
                          "Yes",
                          "No",
                          () => {
                            Loading.dots({
                              svgColor: "rgba(159, 3, 3, 0.8)",
                            });
                            try {
                              changePaymentStatus({
                                order_id: fetchedOrderDetails.id,
                                status: "REFUNDED",
                                invoiceNinjaAPI: selectedCountry?.invoiceNinjaAPI,
                              }).then((response) => {
                                if (response.data?.status == "success") {
                                  Notify.success("Status changed to 'Refunded'!");
                                }
                              });
                            } catch (e) {
                            } finally {
                              Loading.remove(500);
                            }
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      Mark as Refund
                    </MDButton>
                  </Tooltip>
                )}
              </>
            ) : fetchedOrderDetails.payment_method == "CREDIT" ? (
              <>
                {fetchedOrderDetails.payment_status == "PENDING" && (
                  <Tooltip title="Mark as Paid" placement="top">
                    <MDButton
                      variant="gradient"
                      size="small"
                      color="success"
                      sx={{
                        ml: 1,
                        color: "#fff",
                        fontSize: "10px!important",
                        fontWeight: "400",
                      }}
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to mark this as 'Paid'?",
                          "Yes",
                          "No",
                          () => {
                            Loading.dots({
                              svgColor: "rgba(159, 3, 3, 0.8)",
                            });
                            try {
                              changePaymentStatus({
                                order_id: fetchedOrderDetails.id,
                                status: "PAID",
                                invoiceNinjaAPI: selectedCountry?.invoiceNinjaAPI,
                              }).then((response) => {
                                if (response.data?.status == "success") {
                                  Notify.success("Status changed to 'Paid'!");
                                }
                              });
                            } catch (e) {
                              console.log(e);
                            } finally {
                              Loading.remove(500);
                            }
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      Mark as Paid
                    </MDButton>
                  </Tooltip>
                )}
              </>
            ) : (
              ""
            )}
            {!["PENDING", "REFUNDED"].includes(fetchedOrderDetails.payment_status) && (
              <>
                {fetchedOrderDetails.refunded_amount == null ||
                fetchedOrderDetails.refunded_amount != fetchedOrderDetails.grandtotal ? (
                  <RefundDialog order={order} remainingAmount={refundDifference} />
                ) : (
                  ""
                )}
              </>
            )}
            {fetchedOrderDetails.payment_status == "PAID" && (
              <Tooltip title="Resend Order Placed Email" placement="top">
                <MDButton
                  variant="gradient"
                  size="small"
                  color="warning"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => {
                    Confirm.show(
                      `Please Confirm`,
                      "Are you sure you want to resend the 'Order Placed' email?",
                      "Yes",
                      "No",
                      () => {
                        Loading.dots({
                          svgColor: "rgba(159, 3, 3, 0.8)",
                        });
                        resendOrderPlacedEmail({ orderId: fetchedOrderDetails.id });
                        Notify.success("Order Placed email sent successfully!");
                        Loading.remove(500);
                      },
                      () => {},
                      {}
                    );
                  }}
                >
                  Resend Order Placed Email
                </MDButton>
              </Tooltip>
            )}
          </MDBox>
          <MDBox sx={{ mt: 2 }}>
            <GridContainer>
              {fetchedOrderDetails.id && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock blockTitle="Order ID" blockDesc={`#${fetchedOrderDetails.id}`} />
                </GridItem>
              )}
              {fetchedOrderDetails.datetime ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Ordered Date"
                    blockDesc={formatDate(fetchedOrderDetails.datetime)}
                  />
                </GridItem>
              ) : (
                ""
              )}
              {fetchedOrderDetails.reseller_id && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Reseller ID"
                    blockDesc={`#${fetchedOrderDetails.reseller_id}`}
                  />
                </GridItem>
              )}
              {fetchedOrderDetails.contact_name && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Reseller Name"
                    blockDesc={fetchedOrderDetails.contact_name}
                  />
                </GridItem>
              )}
              {fetchedOrderDetails.reseller?.companies?.name && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Company Name"
                    blockDesc={
                      <>
                        {fetchedOrderDetails.reseller?.companies?.name}{" "}
                        {fetchedOrderDetails.reseller.sub_company_name && (
                          <MDBadge
                            badgeContent={fetchedOrderDetails.reseller.sub_company_name}
                            color="primary"
                            variant="gradient"
                            size="sm"
                          />
                        )}
                      </>
                    }
                  />
                </GridItem>
              )}
              {/* {fetchedOrderDetails.reseller && fetchedOrderDetails.reseller.email && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Reseller Email"
                    blockDesc={`${fetchedOrderDetails.reseller.email}`}
                  />
                </GridItem>
              )} */}
              {fetchedOrderDetails.contact_phone && (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Reseller Contact"
                    blockDesc={fetchedOrderDetails.contact_phone}
                  />
                </GridItem>
              )}
              {fetchedOrderDetails.contact_phone && (
                <GridItem
                  xs={12}
                  sm={12}
                  md={4}
                  lg={4}
                  xl={4}
                  sx={{
                    "& .MuiBadge-badge": {
                      textTransform: "capitalize",
                      padding: "0.3em 0.6em",
                    },
                  }}
                >
                  <DetailBlock
                    blockTitle={
                      <>
                        <span>Reseller Account</span>
                        {fetchedOrderDetails.reseller.email && (
                          <MDBadge
                            badgeContent="Inactive"
                            color="error"
                            variant="gradient"
                            size="sm"
                          />
                        )}{" "}
                      </>
                    }
                    blockDesc={fetchedOrderDetails.reseller.email}
                  />
                </GridItem>
              )}
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <DetailBlock
                  blockTitle="PO Number"
                  icon={
                    <Tooltip
                      title={fetchedOrderDetails.po_number ? "Edit" : "Add PO number"}
                      placement="top"
                    >
                      <IconButton
                        aria-label="delete"
                        color="warning"
                        size="small"
                        sx={{
                          marginLeft: "10px",
                          marginTop: "-1px",
                          "& .MuiSvgIcon-root": { fontSize: "1rem!important" },
                        }}
                        onClick={() => setOpenPOEdit(true)}
                      >
                        {fetchedOrderDetails.po_number ? (
                          <EditIcon fontSize="inherit" />
                        ) : (
                          <AddIcon fontSize="inherit" />
                        )}
                      </IconButton>
                    </Tooltip>
                  }
                  blockDesc={fetchedOrderDetails.po_number ? fetchedOrderDetails.po_number : "N/A"}
                />
              </GridItem>
              {fetchedOrderDetails.payment_method ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Payment Method"
                    blockDesc={fetchedOrderDetails.payment_method}
                  />
                </GridItem>
              ) : (
                ""
              )}
              {fetchedOrderDetails.payment_status == "PENDING" ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Payment Status"
                    blockDesc={
                      <MDBadge badgeContent="Pending" color="info" variant="gradient" size="sm" />
                    }
                  />
                </GridItem>
              ) : fetchedOrderDetails.payment_status == "PAID" ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Payment Status"
                    blockDesc={
                      <MDBox sx={{ display: "flex", gap: 0.5 }}>
                        <MDBadge
                          badgeContent="Paid"
                          color="success"
                          variant="gradient"
                          size="sm"
                          sx={{ mr: 0.5 }}
                        />
                        {fetchedOrderDetails.refunded_amount != null && (
                          <MDBadge badgeContent="P/R" color="error" variant="gradient" size="sm" />
                        )}
                      </MDBox>
                    }
                  />
                </GridItem>
              ) : (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Payment Status"
                    blockDesc={
                      fetchedOrderDetails.payment_status == "REFUNDED" ? (
                        <MDBadge
                          badgeContent="Refunded"
                          color="error"
                          variant="gradient"
                          size="sm"
                        />
                      ) : (
                        <MDBadge
                          badgeContent="Canceled"
                          color="error"
                          variant="gradient"
                          size="sm"
                        />
                      )
                    }
                  />
                </GridItem>
              )}

              {isOrderProductsPhysical ? (
                fetchedOrderDetails.delivery_fee == 0 ? (
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <DetailBlock
                      blockTitle="Shipping status"
                      blockDesc={
                        <MDBadge
                          badgeContent="Free shipping"
                          color="info"
                          variant="gradient"
                          size="sm"
                        />
                      }
                    />
                  </GridItem>
                ) : (
                  <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                    <DetailBlock
                      blockTitle="Shipping status"
                      blockDesc={
                        <MDBadge
                          badgeContent="Paid shipping"
                          color="success"
                          variant="gradient"
                          size="sm"
                        />
                      }
                    />
                  </GridItem>
                )
              ) : (
                ""
              )}

              {fetchedOrderDetails.is_express_shipping ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <DetailBlock
                    blockTitle="Express shipping status"
                    blockDesc={
                      <MDBadge
                        badgeContent="Express shipping"
                        color="info"
                        variant="gradient"
                        size="sm"
                      />
                    }
                  />
                </GridItem>
              ) : (
                ""
              )}

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <Divider />
              </GridItem>
            </GridContainer>
          </MDBox>
          {fetchedOrderDetails && (
            <MDBox>
              <MDTypography component="h6" sx={{ mb: 2 }} color="info">
                Order Items
              </MDTypography>
              {orderProductDetails.length > 0 ? (
                <MDBox
                  sx={{
                    // padding: "5px 12px",
                    // border: "1px solid #b7b7b9",
                    // borderRadius: "0.375rem",
                    marginBottom: "3px",
                    "& table tr td": {
                      fontSize: "12px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      fontWeight: 500,
                    },
                    "& table tr th": {
                      fontSize: "12px",
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      color: "#121212",
                    },
                    "& table tr th .MuiBox-root": {
                      color: "#3f3f3f",
                    },
                    "& table tr td .MuiBox-root": {
                      color: "#3f3f3f",
                    },
                  }}
                >
                  {fetchedOrderDetails.payment_method == "BANK" &&
                    fetchedOrderDetails.payment_status == "PENDING" &&
                    orderProductDetails.filter(
                      (item) => item.status == "PROCESSING" && item.is_digital_good
                    ).length > 0 && (
                      <Alert severity="warning">
                        You've to mark this as 'Paid' in order to update digital keys
                      </Alert>
                    )}
                  <DataTable
                    id={tables.ORDER_PRODUCTS}
                    columns={[
                      {
                        Header: "Product Name",
                        accessor: "name",
                        Cell: ({ row: { original } }) =>
                          original.attributes != "" ? (
                            <MDBox>
                              <MDBox sx={{ fontSize: "13px" }}>{original.name}</MDBox>
                              <MDBox sx={{ fontWeight: 400, fontStyle: "italic" }}>
                                {original.attributes}
                              </MDBox>
                            </MDBox>
                          ) : (
                            <>
                              <MDBox sx={{ fontSize: "13px" }}>{original.name}</MDBox>
                            </>
                          ),
                      },
                      {
                        Header: "Quantity",
                        accessor: "quantity",
                        align: "center",
                      },
                      {
                        Header: "Price",
                        accessor: "price",
                        align: "center",
                        Cell: ({ row: { original } }) => (
                          <>
                            {selectedCountry.currency} {original.price?.toFixed(2)}
                          </>
                        ),
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                        Cell: ({ row: { original } }) =>
                          original.status == "PROCESSING" ? (
                            <>
                              <MDBox>
                                <MDBadge
                                  badgeContent="Processing"
                                  color="info"
                                  variant="gradient"
                                  size="sm"
                                />
                              </MDBox>
                            </>
                          ) : original.status == "SHIPPED" ? (
                            <MDBox>
                              <MDBadge
                                badgeContent="Shipped"
                                color="success"
                                variant="gradient"
                                size="sm"
                              />
                            </MDBox>
                          ) : original.status == "DELIVERED" ? (
                            <MDBox>
                              <MDBadge
                                badgeContent="Delivered"
                                color="secondary"
                                variant="gradient"
                                size="sm"
                              />
                            </MDBox>
                          ) : (
                            <MDBox display="flex" justifyContent="space-between">
                              <MDBadge
                                badgeContent="Cancelled"
                                color="error"
                                variant="gradient"
                                size="sm"
                              />
                              {original.note && (
                                <Tooltip title={original.note} placement="top">
                                  <InfoOutlinedIcon
                                    sx={{
                                      ml: 2,
                                      fontSize: "16px!important",
                                    }}
                                  />
                                </Tooltip>
                              )}
                            </MDBox>
                          ),
                      },
                      {
                        Header: "Renewal",
                        accessor: "is_renewal",
                        Cell: ({ row: { original } }) =>
                          original.is_renewal ? (
                            <MDBadge
                              badgeContent="Renewed"
                              color="info"
                              variant="gradient"
                              size="sm"
                            />
                          ) : (
                            <MDBadge
                              badgeContent="Yet to renew"
                              color="warning"
                              variant="gradient"
                              size="sm"
                            />
                          ),
                      },
                      {
                        Header: "Expiry Date",
                        accessor: "expiryDate",
                        Cell: ({ row: { original } }) => (
                          <MDBox display="flex" justifyContent="space-between" alignItems="center">
                            {original.license_expiry_date ? (
                              <span>{moment(original.license_expiry_date).format(dateFormat)}</span>
                            ) : (
                              <span>Unknown</span>
                            )}
                            <MDBox>
                              <EditExpiryDateDialog
                                orderProductId={original.id}
                                currentDate={original.license_expiry_date}
                              />
                              {original.license_expiry_date != null && (
                                <Tooltip title="Reset date" placement="top">
                                  <IconButton
                                    aria-label="edit"
                                    color="error"
                                    size="small"
                                    sx={{
                                      marginLeft: "2px",
                                      marginTop: "-1px",
                                      "& .MuiSvgIcon-root": { fontSize: "1rem!important" },
                                    }}
                                    onClick={() => {
                                      Confirm.show(
                                        `Please Confirm`,
                                        "Are you sure you want to reset the expiry date?",
                                        "Yes",
                                        "No",
                                        () => resetExpiryDate(original.id),
                                        () => {},
                                        {}
                                      );
                                    }}
                                  >
                                    <RestartAltIcon fontSize="inherit" />
                                  </IconButton>
                                </Tooltip>
                              )}
                            </MDBox>
                          </MDBox>
                        ),
                      },
                      {
                        Header: "Actions",
                        accessor: "actions",
                        disableSortBy: true,
                        disableFilters: true,
                        show: true,
                        align: "right",
                        // eslint-disable-next-line react/display-name
                        Cell: ({ row: { original } }) => (
                          <div className="actions-right">
                            {(fetchedOrderDetails.payment_status == "PAID" &&
                              original.is_digital_good &&
                              original.status == "PROCESSING") ||
                            (fetchedOrderDetails.payment_method == "CREDIT" &&
                              fetchedOrderDetails.payment_status == "PENDING" &&
                              original.is_digital_good &&
                              original.status == "PROCESSING") ? (
                              <>
                                <Tooltip title="Mark as Delivered" placement="top">
                                  <MDButton
                                    variant="gradient"
                                    size="small"
                                    color="secondary"
                                    sx={{
                                      ml: 1,
                                      color: "#fff",
                                      fontSize: "10px!important",
                                      fontWeight: "400",
                                    }}
                                    onClick={() => {
                                      Confirm.show(
                                        `Please Confirm`,
                                        "There are digital keys that need to be assigned, do you want to assign them?",
                                        "Yes",
                                        "No",
                                        () => {
                                          let data = {
                                            status: "DELIVERED",
                                            productId: original.product_id,
                                            orderId: fetchedOrderDetails.id,
                                            orderProductId: original.id,
                                            product_name: original.name,
                                            product_variation: original.attributes
                                              ? original.attributes
                                              : "",
                                            note: "",
                                          };
                                          handleChangeDigitalProductsDeliveryStatus(data);
                                        },
                                        () => {},
                                        {}
                                      );
                                    }}
                                  >
                                    Mark as delivered
                                  </MDButton>
                                </Tooltip>
                              </>
                            ) : (
                              <>
                                {original.status == "PROCESSING" && (
                                  <Tooltip title="Mark as Cancelled" placement="top">
                                    <MDButton
                                      variant="gradient"
                                      size="small"
                                      color="primary"
                                      sx={{
                                        ml: 1,
                                        color: "#fff",
                                        fontSize: "10px!important",
                                        fontWeight: "400",
                                      }}
                                      onClick={() => {
                                        setCanceledData({
                                          ...canceledData,
                                          status: "CANCELED",
                                          productId: original.product_id,
                                          orderId: fetchedOrderDetails.id,
                                          order_product_id: original.id,
                                        });
                                        setRejectNoteOpen(true);
                                      }}
                                    >
                                      Mark as cancelled
                                    </MDButton>
                                  </Tooltip>
                                )}
                                {(!original.is_digital_good &&
                                  fetchedOrderDetails.payment_status == "PAID" &&
                                  original.status == "PROCESSING") ||
                                (fetchedOrderDetails.payment_method == "CREDIT" &&
                                  fetchedOrderDetails.payment_status == "PENDING" &&
                                  original.status == "PROCESSING") ? (
                                  <>
                                    <Tooltip title="Mark as Shipped" placement="top">
                                      <MDButton
                                        variant="gradient"
                                        size="small"
                                        color="info"
                                        sx={{
                                          ml: 1,
                                          color: "#fff",
                                          fontSize: "10px!important",
                                          fontWeight: "400",
                                        }}
                                        onClick={() => {
                                          Confirm.show(
                                            `Please Confirm`,
                                            "Are you sure you want to mark as 'Shipped'?",
                                            "Yes",
                                            "No",
                                            () => {
                                              // Loading.dots({
                                              //   svgColor: "rgba(159, 3, 3, 0.8)",
                                              // });
                                              let data = {
                                                status: "SHIPPED",
                                                productId: original.product_id,
                                                orderId: fetchedOrderDetails.id,
                                                note: "",
                                              };
                                              data.order_product_id = original.id;
                                              setDataForTrackingLink(data);
                                              setOpenTrackingDialog(true);
                                              // if(original.tracking_link) {

                                              // } else {
                                              //   try {
                                              //     changeDeliveryStatus({
                                              //       order_product_id: original.id,
                                              //       data: { ...data },
                                              //     }).then((response) => {
                                              //       if (response.data?.status == "success") {
                                              //         Notify.success("Status changed!");
                                              //         triggerProductsByOrderId(
                                              //           fetchedOrderDetails.id
                                              //         );
                                              //       }
                                              //     });
                                              //   } catch (e) {
                                              //   } finally {
                                              //     Loading.remove(500);
                                              //   }
                                              // }
                                            },
                                            () => {},
                                            {}
                                          );
                                        }}
                                      >
                                        Mark as shipped
                                      </MDButton>
                                    </Tooltip>
                                    <TrackingLinkDialog
                                      openTrackingDialog={openTrackingDialog}
                                      setOpenTrackingDialog={setOpenTrackingDialog}
                                      onTrackingLinkSubmit={onTrackingLinkSubmit}
                                      dataForTrackingLink={dataForTrackingLink}
                                    />
                                  </>
                                ) : (
                                  ""
                                )}
                                {original.status == "DELIVERED" && (
                                  <Tooltip title="Resend delivered email" placement="top">
                                    <MDButton
                                      variant="gradient"
                                      size="small"
                                      color="warning"
                                      sx={{
                                        mx: 1,
                                        color: "#fff",
                                        fontSize: "10px!important",
                                        fontWeight: "400",
                                      }}
                                      onClick={() => {
                                        Confirm.show(
                                          `Please Confirm`,
                                          "Are you sure you want resend the delivered email?",
                                          "Yes",
                                          "No",
                                          () => {
                                            Loading.dots({
                                              svgColor: "rgba(159, 3, 3, 0.8)",
                                            });
                                            resendDeliveredEmail({ order_product_id: original.id });
                                            Notify.success("Successfully sent!");
                                            Loading.remove(500);
                                          },
                                          () => {},
                                          {}
                                        );
                                      }}
                                    >
                                      Resend email
                                    </MDButton>
                                  </Tooltip>
                                )}
                                {original.status == "SHIPPED" && (
                                  <Tooltip title="Mark as Delivered" placement="top">
                                    <MDButton
                                      variant="gradient"
                                      size="small"
                                      color="secondary"
                                      sx={{
                                        ml: 1,
                                        color: "#fff",
                                        fontSize: "10px!important",
                                        fontWeight: "400",
                                      }}
                                      onClick={() => {
                                        Confirm.show(
                                          `Please Confirm`,
                                          "Are you sure you want to mark as 'Delivered'?",
                                          "Yes",
                                          "No",
                                          () => {
                                            Loading.dots({
                                              svgColor: "rgba(159, 3, 3, 0.8)",
                                            });
                                            let data = {
                                              status: "DELIVERED",
                                              productId: original.product_id,
                                              orderId: fetchedOrderDetails.id,
                                              note: "",
                                            };
                                            try {
                                              changeDeliveryStatus({
                                                order_product_id: original.id,
                                                data: { ...data },
                                              }).then((response) => {
                                                if (response.data?.status == "success") {
                                                  Notify.success("Status changed!");
                                                  triggerProductsByOrderId(fetchedOrderDetails.id);
                                                }
                                              });
                                            } catch (e) {
                                            } finally {
                                              Loading.remove(500);
                                            }
                                          },
                                          () => {},
                                          {}
                                        );
                                      }}
                                    >
                                      Mark as delivered
                                    </MDButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                            {original.is_digital_good && original.product_id ? (
                              <DigitalKeys
                                product_id={original.product_id}
                                order_product_id={original.id}
                                product_name={original.name}
                                product_variation={original.attributes ? original.attributes : ""}
                                order_id={original.order_id}
                                currentDigitalKeys={digitalKeysByProduct}
                              />
                            ) : original.tracking_link ? (
                              <Tooltip title="View Tracking Link" placement="top">
                                <IconButton
                                  aria-label="view"
                                  size="small"
                                  target="_blank"
                                  // href={
                                  //   process.env.REACT_APP_RESELLER_PORTAL +
                                  //   "/track/" +
                                  //   original.tracking_id
                                  // }
                                  href={original.tracking_link}
                                >
                                  <LocationOnRoundedIcon color="info" fontSize="inherit" />
                                </IconButton>
                              </Tooltip>
                            ) : (
                              ""
                            )}
                            <RejectNote
                              dialogTitle="Cancel Note"
                              dialogNote={`A note is required in order to cancel an order product: (${original.name})`}
                              openNote={rejectNoteOpen}
                              closeNote={setRejectNoteOpen}
                              onRejectSubmit={onDeliveryStatusCanceledSubmit}
                              submitButtonTitle="Submit"
                            />
                            {/* {original.status == 'PROCESSING' && <Tooltip title="Add" placement="Change status to Shipped">
                          <Button variant="outlined" size="small" sx={{ ml: 1 }} onClick={() => {
                            Loading.dots({
                              svgColor: "rgba(159, 3, 3, 0.8)",
                            })
                            try {
                              changeStatus(original.id, "Shipped").then((response) => {
                                console.log(response);
                                triggerProductsByOrderId(order.id);
                              });
                            } catch (e) {

                            } finally {
                              Loading.remove(500);
                            }
                          }}>
                            Cancelled
                          </Button>
                        </Tooltip>} */}
                          </div>
                        ),
                      },
                    ]}
                    data={orderProductDetails || []}
                    isSorted={true}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    hideColumnSelection={true}
                    noEndBorder
                    canSearch={false}
                    unlimitedRows={true}
                  />
                  {fetchedDigitalKeys.length > 0 && (
                    <DigitalKeysUpdateDialog
                      openDigitalKeysUpdateDialog={openDigitalKeysUpdateDialog}
                      setOpenDigitalKeysUpdateDialog={setOpenDigitalKeysUpdateDialog}
                      setFetchedDigitalKeys={setFetchedDigitalKeys}
                      fetchedDigitalKeys={fetchedDigitalKeys}
                      onDigitalKeysSubmit={onDigitalKeysSubmit}
                      setTempDeliveredData={setTempDeliveredData}
                      tempDeliveredData={tempDeliveredData}
                    />
                  )}
                </MDBox>
              ) : (
                <Alert severity="warning">No Order products found!</Alert>
              )}
              <MDBox sx={{ mt: 2 }}>
                <GridContainer>
                  {fetchedOrderDetails.subtotal ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Subtotal"
                        blockDesc={`${
                          selectedCountry.currency
                        } ${fetchedOrderDetails.subtotal?.toFixed(2)}`}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {fetchedOrderDetails.delivery_fee ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Delivery Fee"
                        blockDesc={`${
                          selectedCountry.currency
                        } ${fetchedOrderDetails.delivery_fee?.toFixed(2)}`}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {fetchedOrderDetails.tax ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Tax"
                        blockDesc={`${selectedCountry.currency} ${fetchedOrderDetails.tax?.toFixed(
                          2
                        )}`}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}
                  {fetchedOrderDetails.grandtotal ? (
                    <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                      <DetailBlock
                        blockTitle="Total"
                        blockDesc={`${
                          selectedCountry.currency
                        } ${fetchedOrderDetails.grandtotal?.toFixed(2)}`}
                      />
                    </GridItem>
                  ) : (
                    ""
                  )}

                  <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Divider />
                  </GridItem>
                  {/* {fetchedOrderDetails.delivery_address && (
                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                      <DetailBlock
                        blockTitle="Delivery Address"
                        blockDesc={fetchedOrderDetails.delivery_address}
                      />
                    </GridItem>
                  )} */}
                  {fetchedOrderDetails.order_delivery_details &&
                  fetchedOrderDetails.order_delivery_details.length > 0 ? (
                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                      <DetailBlock
                        blockTitle="Delivery Details"
                        blockDesc={
                          <>
                            {fetchedOrderDetails.order_delivery_details[0].contact_name}
                            <br />
                            {fetchedOrderDetails.order_delivery_details[0].delivery_address1}
                            <br />
                            {fetchedOrderDetails.order_delivery_details[0].delivery_address2}
                            <br />
                            {fetchedOrderDetails.order_delivery_details[0].contact_phone}
                          </>
                        }
                      />
                    </GridItem>
                  ) : (
                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                      <DetailBlock
                        blockTitle="Delivery Details"
                        blockDesc={fetchedOrderDetails.billing_address}
                      />
                    </GridItem>
                  )}
                  {fetchedOrderDetails.billing_address && (
                    <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                      <DetailBlock
                        blockTitle="Billing Address"
                        blockDesc={fetchedOrderDetails.billing_address}
                      />
                    </GridItem>
                  )}
                </GridContainer>
              </MDBox>
            </MDBox>
          )}
          {refundHistory.length > 0 &&
            (fetchedOrderDetails.refunded_amount != null ? (
              <Box mt={2}>
                <RefundHistory orderId={fetchedOrderDetails.id} tableData={refundHistory} />
              </Box>
            ) : (
              ""
            ))}
          <EditPODialog
            orderId={fetchedOrderDetails.id}
            currentPO={fetchedOrderDetails.po_number}
            openPOEdit={openPOEdit}
            setOpenPOEdit={setOpenPOEdit}
            invoiceNinjaAPI={selectedCountry.invoiceNinjaAPI}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: "stretch" }}>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box>
              {fetchedOrderDetails.payment_method == "BANK" ? (
                <BankReceipts
                  orderId={fetchedOrderDetails.id}
                  bankRecieptPath={
                    order.bank_reciepts && order.bank_reciepts.length > 0
                      ? order.bank_reciepts[order.bank_reciepts.length - 1].path
                      : ""
                  }
                />
              ) : (
                ""
              )}
            </Box>
            <Box>
              {fetchedOrderDetails.invoice_link && (
                <MDButton
                  type="submit"
                  color="primary"
                  variant="gradient"
                  target="_blank"
                  href={fetchedOrderDetails.invoice_link}
                  sx={{ mr: 1 }}
                >
                  View Invoice
                </MDButton>
              )}
              {/* {fetchedOrderDetails.invoice_id && (
                <MDButton
                  type="submit"
                  color="primary"
                  variant="gradient"
                  onClick={() => {
                    downloadInvoice({ orderId: fetchedOrderDetails.id, countryId: 14 });
                  }}
                  sx={{ mr: 1 }}
                >
                  Download Invoice
                </MDButton>
              )} */}
              <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
                Cancel
              </MDButton>
            </Box>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
};

OrderDetails.propTypes = {
  order: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired,
};

export default OrderDetails;
