import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selected: {
    id: "",
    name: "",
    phone_code: "",
    symbol: "",
    currency: "",
    invoiceNinjaAPI: "",
    isZohoEnabled: false,
    zohoRefreshToken: "",
    zohoAccessToken: "",
  },
  selectedURL: {
    id: 0,
    url: "",
    c_key: "",
    c_secret: "",
  },
  tempURLs: []
};

const slice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setSelectedCountry: (countries, action) => {
      countries.selected = { ...action.payload };
    },
    setSelectedCountryURL: (countries, action) => {
      countries.selectedURL = { ...action.payload };
    },
    resetSelectedCountryURL: (countries) => {
      countries.selectedURL = {
        id: 0,
        url: "",
        c_key: "",
        c_secret: "",
      };
    },

    setTempURLs: (countries, action) => {
      countries.tempURLs = [...action.payload];
    }

  },
});

export const globalCountry = (state) => state.UI.countries.selected;

export const selectedCountryURL = (state) => state.UI.countries.selectedURL;

export const getTempURLs = (state) => state.UI.countries.tempURLs;

export const { setSelectedCountry, setSelectedCountryURL, resetSelectedCountryURL, setTempURLs } = slice.actions;

export default { name: slice.name, reducer: slice.reducer };
