/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";

// other imports
import { EditorState, ContentState, convertFromHTML, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// notifications
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

// @mui
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Tooltip from "@mui/material/Tooltip";

// mui icons
import Edit from "@mui/icons-material/Edit";

//components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// rtk queries
import {
  useGetAllCountriesQuery,
  useUpdateCountryMutation,
  useGetAllStatesByCountryIdQuery,
} from "services/countries";

// views
import Instructions from "../InstructionsDialog";
import instructions from "../data/instructions";

// utils, schemas
import { countriesSchema } from "../schemas/_model";

// styles
import "../assets/styles.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditCountry({ countryData }) {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());

  const [convertedContent, setConvertedContent] = useState(null);

  const [openEdit, setOpenEdit] = useState(false);

  // const [dynamicStates, setDynamicStates] = useState([]);

  const initialFormState = {
    name: "",
    // currency: "",
    // phone_code: "",
    // consumer_key: "",
    // consumer_secret: "",
    isTaxIncluded: true,
    deliveryCharges: 0,
    freeDeliveryAbove: 0,
    expressShippingFee: 0,
    taxPercentage: 0,
    stripeSecretKey: "",
    stripePublicKey: "",
    invoiceNinjaAPI: "",
    regionalTaxes: [],
    isEnableZoho: false,
    zohoClientId: "",
    zohoClientSecret: "",
    zohoOrganizationId: "",
    zohoRefreshToken: "",
    zohoAccessToken: ""
  };

  const { data: countries = [], isLoading, isSuccess } = useGetAllCountriesQuery();

  const [updateCountry, { isLoading: isUpdateCountryLoading }] = useUpdateCountryMutation();

  const {
    data: statesByCountryId = [],
    isLoading: isLoadingStates,
    isSuccess: isSuccessStates,
  } = useGetAllStatesByCountryIdQuery(countryData.id);

  // const [
  //   triggerStatesByCountryId,
  //   { data: statesByCountryId = [], isLoading: isLoadingStates, isSuccess: isSuccessStates },
  // ] = useLazyGetAllStatesByCountryIdQuery();

  const { control, watch, handleSubmit, setValue } = useForm({
    resolver: yupResolver(countriesSchema),
    defaultValues: initialFormState,
  });

  const {
    fields: stateFields,
    append: appendState,
    remove: removeState,
  } = useFieldArray({ name: "regionalTaxes", control });

  const isTaxIncluded = watch("isTaxIncluded");

  const isEnableZoho = watch("isEnableZoho");

  const countryField = watch("name");

  const handleClickOpen = () => {
    setValue("name", countryData.id);
    setValue("stripePublicKey", countryData.stripe_public_key);
    setValue("stripeSecretKey", countryData.stripe_secret_key);
    setValue("stripeWebhookSecret", countryData.stripe_webhook_secret);
    setValue("isTaxIncluded", countryData.is_tax_included);
    setValue("taxPercentage", countryData.tax_percentage);
    setValue("deliveryCharges", countryData.delivery_charges);
    setValue("freeDeliveryAbove", countryData.free_delivery_above);
    setValue("invoiceNinjaAPI", countryData.invoice_ninja_api);
    setValue("expressShippingFee", countryData.express_shipping_fee);

    // zoho fields

    setValue("isEnableZoho", countryData.is_zoho_enabled);

    if(countryData.zoho_client_id) {
      setValue("zohoClientId", countryData.zoho_client_id);
    } else {
      setValue("zohoClientId", "");
    }

    if(countryData.zoho_client_secret) {
      setValue("zohoClientSecret", countryData.zoho_client_secret);
    } else {
      setValue("zohoClientSecret", "");
    }

    if(countryData.zoho_organization_id) {
      setValue("zohoOrganizationId", countryData.zoho_organization_id);
    } else {
      setValue("zohoOrganizationId", "");
    }

    if(countryData.zoho_refresh_token) {
      setValue("zohoRefreshToken", countryData.zoho_refresh_token);
    } else {
      setValue("zohoRefreshToken", "");
    }

    if(countryData.zoho_access_token) {
      setValue("zohoAccessToken", countryData.zoho_access_token);
    } else {
      setValue("zohoAccessToken", "");
    }

    if (countryData.terms_and_conditions != null) {
      const blocksFromHTML = convertFromHTML(countryData.terms_and_conditions);
      const contentState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );

      const initialEditorState = EditorState.createWithContent(contentState);

      setEditorState(initialEditorState);
    }
    setOpenEdit(true);
  };
  const handleClose = () => {
    setOpenEdit(false);
  };

  useEffect(() => {
    isLoadingStates
      ? Loading.dots({
          svgColor: "rgba(159, 3, 3, 0.8)",
        })
      : Loading.remove(500);
  }, [openEdit, isLoadingStates]);

  useEffect(() => {
    if(isEnableZoho) {
      if(countryData.zoho_client_id) {
        setValue("zohoClientId", countryData.zoho_client_id);
      } else {
        setValue("zohoClientId", "");
      }
    } else {
      if(countryData.invoice_ninja_api) {
        setValue("invoiceNinjaAPI", countryData.invoice_ninja_api);
      } else {
        setValue("invoiceNinjaAPI", "");
      }
    }
  }, [isEnableZoho])

  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(html);
  }, [editorState]);

  useEffect(() => {
    if (statesByCountryId) {
      setValue("regionalTaxes", []);
      setValue("isTaxIncluded", true);
      let filterStates = statesByCountryId.map((item) => ({
        id: item.id,
        value: item.tax_percentage,
      }));
      appendState(filterStates);
    }
  }, [statesByCountryId]);

  const removeFields = (index) => {
    remove(index);
    // let data = [...dynamicFields];
    // data.splice(index, 1);
    // setDynamicFields(data);
  };

  // const removeStateFields = (index) => {
  //   let data = [...dynamicStates];
  //   data.splice(index, 1);
  //   setDynamicStates(data);
  // };

  const onSubmit = async (data) => {
    if (!isTaxIncluded) {
      if (data.regionalTaxes.length == 0) {
        Notify.failure("You must add at least one tax percentage");
        return;
      }
    } else {
      data.regionalTaxes = [];
    }

    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    countries.map((item) => {
      if (item.id == data.name) {
        data.regNoTerm = item.reg_no_term != null ? item.reg_no_term : "sss";
        data.taxNoTerm = item.tax_no_term != null ? item.tax_no_term : "sss";
      }
    });

    data.termsAndConditions = convertedContent;

    let id = parseInt(data.name);
    data.id = id;

    data.countryId = id;

    // deleting unnecessary stuff
    delete data.name;
    delete data.terms_and_conditions;

    try {
      updateCountry({ countryId: id, data: data }).then((response) => {
        if (response.error) {
          Notify.failure(response.error.data.message);
        } else {
          handleClose();

          setTimeout(() => {
            Loading.remove(500);
          }, 500);

          setTimeout(() => {
            Notify.success("The country has been updated!");
          }, 500);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Tooltip title="Edit" placement="top">
        <IconButton aria-label="view" size="small" onClick={handleClickOpen}>
          <Edit color="warning" fontSize="inherit" />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        maxWidth="xl"
        sx={{
          "& .MuiPaper-root": {
            width: "900px",
          },
        }}
        open={openEdit}
      >
        <DialogTitle sx={{ m: 0, p: 2, color: "#3f3f3f" }} id="customized-dialog-title">
          Edit Region
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <br />
          <form>
            <GridContainer>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledSelect
                  name={"name"}
                  control={control}
                  label="Select Country *"
                  menu={{
                    id: "id",
                    value: "name",
                    title: "Countries",
                    list: countries,
                  }}
                  sx={{
                    "& .MuiFormLabel-root": {
                      backgroundColor: "#fff",
                    },
                    "& .MuiInputBase-root": {
                      height: "44px",
                    },
                  }}
                  inputProps={{
                    readOnly: true,
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="deliveryCharges"
                  control={control}
                  labelText="Delivery charges *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="freeDeliveryAbove"
                  control={control}
                  labelText="Free delivery above *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="expressShippingFee"
                  control={control}
                  labelText="Express shipping fee *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    type: "number",
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} sx={{ mb: 1 }}>
                <Divider />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="stripeSecretKey"
                  control={control}
                  labelText="Stripe Secret Key *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Instructions data={instructions.stripe_secret} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="stripePublicKey"
                  control={control}
                  labelText="Stripe Public Key *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Instructions data={instructions.stripe_public} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="stripeWebhookSecret"
                  control={control}
                  labelText="Stripe Webhook Secret *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Instructions data={instructions.stripe_webhook} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={12} sx={{ mb: 1 }}>
                <Divider />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="isEnableZoho"
                  control={control}
                  label="Is Zoho enabled?"
                />
              </GridItem>

              {isEnableZoho ? <>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="zohoClientId"
                    control={control}
                    labelText="Zoho Client ID *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    // inputProps={{
                    //   endAdornment: (
                    //     <InputAdornment position="end">
                    //       <Instructions data={instructions.invoice_ninja_key} />
                    //     </InputAdornment>
                    //   ),
                    // }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="zohoClientSecret"
                    control={control}
                    labelText="Zoho Client Secret *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="zohoOrganizationId"
                    control={control}
                    labelText="Zoho Organization ID *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="zohoRefreshToken"
                    control={control}
                    labelText="Zoho Refresh Token *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="zohoAccessToken"
                    control={control}
                    labelText="Zoho Access Token *"
                    formControlProps={{
                      fullWidth: true,
                    }}
                  />
                </GridItem>
              </> : <>
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledTextInput
                  name="invoiceNinjaAPI"
                  control={control}
                  labelText="InvoiceNinja API Key *"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <Instructions data={instructions.invoice_ninja_key} />
                      </InputAdornment>
                    ),
                  }}
                />
              </GridItem>
              </>}

              <GridItem xs={12} sm={12} sx={{ mb: 1 }}>
                <Divider />
              </GridItem>

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <ControlledCheckbox
                  name="isTaxIncluded"
                  control={control}
                  label="Is Tax included?"
                />
              </GridItem>

              {isTaxIncluded ? (
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <ControlledTextInput
                    name="taxPercentage"
                    control={control}
                    labelText="Tax Percentage"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      type: "number",
                    }}
                  />
                </GridItem>
              ) : (
                <GridItem xs={12} sm={12}>
                  <Box
                    px={2}
                    pb={2}
                    p1={1}
                    sx={{ border: "0.0625rem solid #f0f2f5", borderRadius: "0.375rem" }}
                    id="dynamicStatesElement"
                  >
                    <Box display="flex" justifyContent="space-between" my={1}>
                      <Typography
                        variant="h6"
                        sx={{
                          fontWeight: 500,
                          color: "#3f3f3f",
                        }}
                      >
                        States
                      </Typography>
                      {/* <MDButton color="info" variant="gradient" iconOnly onClick={addStateFields}>
                        <AddBox sx={{}} />
                      </MDButton> */}
                    </Box>
                    <br />
                    {stateFields.map((input, index) => {
                      return (
                        <GridContainer key={index}>
                          <GridItem xs={12} sm={12} md={11} lg={11} xl={11}>
                            <GridContainer>
                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledSelect
                                  name={`regionalTaxes.${index}.id`}
                                  control={control}
                                  label="Select State *"
                                  menu={{
                                    id: "id",
                                    value: "state_name",
                                    title: "State",
                                    list: statesByCountryId,
                                  }}
                                  sx={{
                                    "& .MuiFormLabel-root": {
                                      backgroundColor: "#fff",
                                    },
                                    "& .MuiInputBase-root": {
                                      height: "44px",
                                    },
                                  }}
                                  inputProps={{
                                    readOnly: true,
                                  }}
                                />
                              </GridItem>

                              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                                <ControlledTextInput
                                  name={`regionalTaxes.${index}.value`}
                                  control={control}
                                  labelText="Tax percentage *"
                                  formControlProps={{
                                    fullWidth: true,
                                  }}
                                  inputProps={{}}
                                />
                              </GridItem>
                            </GridContainer>
                          </GridItem>
                        </GridContainer>
                      );
                    })}
                  </Box>
                </GridItem>
              )}

              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}></GridItem>

              <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                <br />
                <FormControl variant="outlined">
                  <InputLabel
                    htmlFor="terms-condition"
                    sx={{
                      transform: "translate(12px, -5px) scale(.95)",
                      backgroundColor: "#fff",
                      padding: "0 5px 0 0",
                    }}
                  >
                    Terms And Conditions
                  </InputLabel>
                  <MDBox>
                    <Editor
                      id="terms-condition"
                      editorState={editorState}
                      onEditorStateChange={setEditorState}
                      wrapperClassName="wrapper-class"
                      editorClassName="editor-class"
                      toolbarClassName="toolbar-class"
                    />
                  </MDBox>
                </FormControl>
              </GridItem>
            </GridContainer>
          </form>
        </DialogContent>
        <DialogActions>
          <MDButton
            type="submit"
            color="primary"
            variant="gradient"
            id="submit"
            onClick={handleSubmit(onSubmit)}
          >
            Update Country
          </MDButton>
          <MDButton color="secondary" variant="gradient" autoFocus onClick={handleClose}>
            Cancel
          </MDButton>
        </DialogActions>
      </BootstrapDialog>
    </>
  );
}

export default EditCountry;
