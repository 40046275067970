/* eslint-disable react/prop-types */
import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";

import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";

import { styled } from "@mui/material/styles";

import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import MDButton from "components/MDButton";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";

import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import MDTypography from "components/MDTypography";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import CircularProgress from "@mui/material/CircularProgress";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import Alert from "components/CustomAlert";

import { dateFormat } from "utils/constants";

import {
  useUploadCAMutation,
  useDeleteUploadedCAMutation,
  useLazyGetRequiredFilesForCompanyQuery,
} from "services/companies";

import { useUploadFileMutation, useDeleteFileMutation } from "services/files";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

registerPlugin(FilePondPluginImagePreview);

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

import MDBox from "components/MDBox";

const SingleCA = ({ id, companyId, filePath, fileType, title }) => {
  const [cAFile, setCAFile] = useState({});

  const [uploadFile, { isLoading: isFileUploading }] = useUploadFileMutation();

  const [uploadCA, { isLoading: isPaymentReceiptUploading }] = useUploadCAMutation();

  // const [triggerGetRequredFilesForCompany] = useLazyGetRequiredFilesForCompanyQuery();

  const [deleteCA, { isLoading: isPaymentReceiptDeleting }] = useDeleteUploadedCAMutation();

  const [file, setFile] = useState([]);

  const handleUpdateFiles = (files) => {
    if (files.length > 0) {
      const photo = files[0].source;
      setFile(photo);
    } else {
      setFile([]);
    }
  };

  function handleSubmit(event) {
    event.preventDefault();
    if (file.length != 0) {
      let formData = new FormData();
      formData.append("file", file);
      uploadFile({ formData: formData, directory: "" }).then((response) => {
        console.log(response.data.response.path);
        if (response.data?.status == "success") {
          let path = response.data.response.path;
          uploadCA({ companyId: companyId, path: path.replace(/\\/g, "/"), type: fileType }).then(
            (response) => {
              if (response.data?.status == "success") Notify.success("Succefully Uploaded!");
            }
          );
        }
      });
    } else {
      Notify.failure("Please select a file");
    }
  }

  const handleDeletePaymentReciept = (data) => {
    deleteCA(data).then((response) => {
      if (response.data) {
        Notify.success("Succefully deleted!");
        // triggerGetRequredFilesForCompany(companyId);
        setCAFile({});
        setFile([]);
      } else {
        Notify.failure("Something went wrong!");
      }
    });
  };

  useEffect(() => {
    if (filePath != "") {
      let fileExtension = filePath.split(".").pop().toLowerCase();
      if (fileExtension == "pdf") {
        setCAFile({
          path: filePath,
          file_type: "pdf",
        });
      } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
        setCAFile({
          path: filePath,
          file_type: "img",
        });
      } else if (["doc", "docx"].includes(fileExtension)) {
        const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
          process.env.REACT_APP_URL + "/" + filePath
        )}&embedded=true`;
        setCAFile({
          path: googleDocsViewerURL,
          file_type: "doc",
        });
      } else {
        setCAFile({
          path: filePath,
          file_type: "img",
        });
      }
    } else {
      setCAFile({});
      setFile([]);
    }
  }, [filePath]);

  return (
    <MDBox>
      <MDTypography variant="h6" sx={{ mb: 1, display: "block" }} color="info">
        {title}
      </MDTypography>
      <MDBox sx={{ border: "0.0625rem solid #f0f2f5", height: "100%" }}>
        {cAFile.path != undefined ? (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              {cAFile.file_type == "pdf" ? (
                <MDBox sx={{ position: "relative", zIndex: 0 }}>
                  <MDBox
                    mb={2}
                    component="iframe"
                    src={process.env.REACT_APP_URL + "/" + cAFile.path}
                    sx={{ width: "100%", height: 700, position: "relative", mt: 3 }}
                  />
                  <Tooltip title="Delete sheet" placement="top">
                    <IconButton
                      sx={{ position: "absolute", zIndex: 1, right: 0, top: 0 }}
                      aria-label="view"
                      size="small"
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this sheet?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ id, filePath });
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              ) : cAFile.file_type == "img" ? (
                <MDBox sx={{ position: "relative", zIndex: 0 }}>
                  <MDBox
                    mb={2}
                    component="img"
                    src={process.env.REACT_APP_URL + "/" + cAFile.path}
                    sx={{ width: "100%", position: "relative", mt: 3 }}
                  />
                  <Tooltip title="Delete sheet" placement="top">
                    <IconButton
                      sx={{ position: "absolute", zIndex: 1, right: 0, top: 0 }}
                      aria-label="view"
                      size="small"
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this sheet?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ id, filePath });
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              ) : cAFile.file_type == "doc" ? (
                <MDBox sx={{ position: "relative", zIndex: 0 }}>
                  <MDBox
                    mb={2}
                    component="iframe"
                    src={googleDocsViewerURL}
                    sx={{ width: "100%", height: 700, position: "relative", mt: 3 }}
                  />
                  <Tooltip title="Delete sheet" placement="top">
                    <IconButton
                      sx={{ position: "absolute", zIndex: 1, right: 0, top: 0 }}
                      aria-label="view"
                      size="small"
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this sheet?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ id, filePath });
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              ) : (
                <MDBox sx={{ position: "relative", zIndex: 0 }}>
                  <MDBox
                    mb={2}
                    component="img"
                    src={process.env.REACT_APP_URL + "/" + cAFile.path}
                    sx={{ width: "100%", position: "relative", mt: 3 }}
                  />
                  <Tooltip title="Delete sheet" placement="top">
                    <IconButton
                      sx={{ position: "absolute", zIndex: 1, right: 0, top: 0 }}
                      aria-label="view"
                      size="small"
                      onClick={() => {
                        Confirm.show(
                          `Please Confirm`,
                          "Are you sure you want to delete this sheet?",
                          "Yes",
                          "No",
                          () => {
                            handleDeletePaymentReciept({ id, filePath });
                          },
                          () => {},
                          {}
                        );
                      }}
                    >
                      <HighlightOffRoundedIcon color="error" fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </MDBox>
              )}
            </GridItem>
          </GridContainer>
        ) : (
          <GridContainer>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert severity="warning">There are no sheet found. Please upload an one</Alert>
            </GridItem>
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              {/* <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleChange} />
                <button type="submit">Upload</button>
              </form> */}
              {/* <button onClick={handleDelete}>Delete</button> */}
              <>
                <Box
                  component={FilePond}
                  sx={{
                    fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
                    "& .filepond--file-action-button": {
                      cursor: "pointer",
                    },
                    "& .filepond--drop-label.filepond--drop-label label": {
                      fontSize: "13px",
                      letterSpacing: "0.5px",
                      fontWeight: "600",
                    },
                  }}
                  instantUpload={false}
                  allowFileSizeValidation={true}
                  name="file"
                  allowFileTypeValidation={true}
                  acceptedFileTypes={[
                    "image/jpeg",
                    "image/jpg",
                    "image/png",
                    "application/msword", // for .doc
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // for .docx
                    "application/pdf",
                  ]}
                  allowMultiple={false}
                  allowFilePoster={false}
                  labelIdle='Drag & Drop file or <span class="filepond--label-action">Browse</span>'
                  onupdatefiles={handleUpdateFiles}
                  allowRevert={false}
                />
                {file.length != 0 && (
                  <Box sx={{ textAlign: "center" }}>
                    <MDButton
                      // component="label"
                      color="primary"
                      variant="gradient"
                      onClick={handleSubmit}
                      startIcon={isFileUploading ? <CircularProgress /> : <CloudUploadIcon />}
                      sx={{ color: "#fff", mb: 1 }}
                    >
                      Upload
                    </MDButton>
                  </Box>
                )}
              </>
            </GridItem>
          </GridContainer>
        )}
      </MDBox>
    </MDBox>
  );
};

SingleCA.propTypes = {
  company_id: PropTypes.number.isRequired,
};

export default SingleCA;
