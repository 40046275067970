/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";

import { styled } from "@mui/material/styles";

import moment from "moment";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

import Box from "@mui/material/Box";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { AddBox } from "@mui/icons-material";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Typography from "@mui/material/Typography";

import ControlledTextInput from "components/FormInputs/ControlledTextInput";
import ControlledDateTime from "components/FormInputs/ControlledDateTime";
import ControlledCheckbox from "components/FormInputs/ControlledCheckbox";
import ControlledSelect from "components/FormInputs/ControlledSelect";
import CustomInputs from "components/CustomInputs";
import { CustomSelect } from "components/CustomSelect";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDBadge from "components/MDBadge";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import { BDMSchema } from "./schemas/_model";

import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Check from "@mui/icons-material/Check";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";
import SettingsIcon from '@mui/icons-material/Settings';

import InputAdornment from "@mui/material/InputAdornment";

import Alert from "components/CustomAlert";

import { tables, dateFormat, dateTimeFormat } from "utils/constants";

import DetailBlock from "components/DetailBlock";

import GenerateTokens from './GenerateTokens';

// rtk queries
import { useGetAllCountriesQuery, useLazyGetAllUrlsByCountryIdQuery } from "services/countries";

import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import StopCircleIcon from '@mui/icons-material/StopCircle';

import { globalCountry } from "store/entities/countries";

import { replaceHTMLEntityAnd } from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ZohoConfigs () {

  const globalSelectedCountry = useSelector(globalCountry);

  const { data: countriesList = [], isLoading, isSuccess } = useGetAllCountriesQuery();

  const [refreshToken, setRefreshToken] = useState('');
  const [accessToken, setAccessToken] = useState('');

  const [selectedCountry, setSelectedCountry] = useState({});

  // const [startScheduler] = useStartSchedulerMutation();

  const dispatch = useDispatch();

  useEffect(() => {
    isLoading ? Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    }) : Loading.remove(500);
  }, [isLoading]);

  useEffect(() => {
    if(countriesList && countriesList.length > 0) {
      let filterCountry = countriesList.filter(item => item.id == globalSelectedCountry.id);
      if(filterCountry && filterCountry.length > 0) {
        setSelectedCountry(filterCountry[0]);
      }
    }
  }, [countriesList]);

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Zoho Configs</MDTypography>}
      />
      <CardContent>
        {selectedCountry && Object.keys(selectedCountry).length > 0 ? <Card>
          <CardHeader
            // avatar={<AccountCircleIcon fontSize="large" color="info" />}
            title={<MDTypography color="info">Generate Zoho Keys</MDTypography>}
          />
          <CardContent>
            <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                  <DetailBlock blockTitle="Refresh Token" blockDesc={selectedCountry.zoho_refresh_token ? <MDTypography variant="p" fontWeight="medium" sx={{ fontStyle: 'italic', mb: 2, overflowX: 'scroll', display: 'block' }}>{selectedCountry.zoho_refresh_token}</MDTypography> : 'N/A'} />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                  <DetailBlock blockTitle="Access Token" blockDesc={selectedCountry.zoho_access_token ? <MDTypography variant="p" fontWeight="medium" sx={{ fontStyle: 'italic', mb: 2, overflowX: 'scroll', display: 'block' }}>{selectedCountry.zoho_access_token}</MDTypography> : 'N/A'} />
                </GridItem><br />
              </GridContainer>
              <GenerateTokens clientId={selectedCountry.zoho_client_id} secretKey={selectedCountry.zoho_client_secret} countryId={selectedCountry.id} />
            <br />
          </CardContent>
        </Card> : "Data not found!"}
      </CardContent>
    </Card>
  );
}

export default ZohoConfigs;
