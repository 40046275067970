import * as yup from "yup";

export const countriesSchema = yup.object().shape({
  name: yup.string().required().label("Country Name"),
  // currency: yup.string().required().label("Currency"),
  // phone_code: yup.string().required().label("Phone Code"),
  // url: yup.string().required().label("URL"),
  // consumer_key: yup.string().required().label("Consumer Key"),
  // consumer_secret: yup.string().required().label("Consumer Secret"),
  isTaxIncluded: yup.bool().label("Tax Included"),
  taxPercentage: yup.number().when(["isTaxIncluded"], {
    is: (isTaxIncluded) => isTaxIncluded === true,
    then: () => yup.number().min(1).required(),
    otherwise: () => yup.number()
  }).default(0).label("Tax Percentage"),
  isEnableZoho: yup.bool().label("is Enable Zoho"),
  zohoClientId: yup.string().when(["isEnableZoho"], {
    is: (isEnableZoho) => isEnableZoho === true,
    then: () => yup.string().required(),
    otherwise: () => yup.string()
  }).default("").label("Zoho Client ID"),
  zohoClientSecret: yup.string().when(["isEnableZoho"], {
    is: (isEnableZoho) => isEnableZoho === true,
    then: () => yup.string().required(),
    otherwise: () => yup.string()
  }).default("").label("Zoho Client Secret"),
  zohoOrganizationId: yup.string().when(["isEnableZoho"], {
    is: (isEnableZoho) => isEnableZoho === true,
    then: () => yup.string().required(),
    otherwise: () => yup.string()
  }).default("").label("Zoho Organization ID"),
  zohoRefreshToken: yup.string().default("").label("Zoho Refresh Token"),
  zohoAccessToken: yup.string().default("").label("Zoho Access Token"),
  deliveryCharges: yup.number().min(0).required().default("").label("Delivery Charges"),
  freeDeliveryAbove: yup.number().min(0).required().default("").label("Free Delivery Above"),
  expressShippingFee: yup.number().min(0).required().default("").label("Express Shipping Fee"),
  stripeSecretKey: yup.string().required().default("").label("Stripe SecretKey"),
  stripePublicKey: yup.string().required().default("").label("Stripe Public Key"),
  stripeWebhookSecret: yup.string().required().default("").label("Stripe Webhook Secret"),
  invoiceNinjaAPI: yup.string().required().default("").label("InvoiceNinja API Key"),
  regionalTaxes: yup.array().of(
    yup.object().shape({
      id: yup.number().min(0).required("State is required"),
      value: yup.number().min(0).required("Tax percentage is required")
    })
  ),
  // terms_and_conditions: yup.string().label("Terms and Condition"),
});
