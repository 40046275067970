/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import axios from "axios";

import { styled } from "@mui/material/styles";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { Confirm } from "notiflix/build/notiflix-confirm-aio";
import { Block } from "notiflix/build/notiflix-block-aio";

// components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import MDAvatar from "components/MDAvatar";
import { CustomSelect } from "components/CustomSelect";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// components
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

// queries
import {
  useGetAllResellerProductsByCountryIdQuery,
  useGetAllResellerProductsQuery,
  useLazyGetAllResellerProductsByCountryIdQuery,
  useDeleteProductMutation,
  useGetWoocommerceSiteProductsMutation,
  usePostProductMutation,
  useUploadProductImageMutation,
  usePutProductMutation,
  useGetDistinctAttributesQuery,
  useResetFeaturedProductsMutation
} from "services/products";

import {
  useGetWoocommerceSiteCategoriesMutation,
  usePostCategoryNameMutation,
  useGetAllResellerProductCategoriesQuery,
  usePutCategoryNameMutation,
  useLazyGetAllResellerProductCategoriesQuery,
} from "services/categories";

import { useGetAllUrlsByCountryIdQuery } from "services/countries";

// import { useGetAllProductsFromStoreUrlQuery } from "services/store";

import {
  globalCountry,
  selectedCountryURL,
  setSelectedCountryURL,
  resetSelectedCountryURL,
} from "store/entities/countries";

import { resetStockId, getCurrentStockId } from "store/entities/stockNotifications";

import {
  setGlobalLoader,
  setGlobalLoaderMessage,
  resetGlobalLoader,
  loaderStatus,
  globalLoaderMessage,
} from "store/ui/loader";

import { actions as tableActions } from "store/ui/table";

import Alert from "components/CustomAlert";

import CircularProgress from "@mui/material/CircularProgress";

import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import noPreview from "assets/images/no-preview.png";

import EditInventoryDialog from "./EditInventoryDialog";
import AddInventoryDialog from "./AddInventoryDialog";
import AddInventory from "./AddInventory";

import ProductReorder from "./ProductReorder";

import AddToReOrderList from "./AddToReOrderList";

import { tables } from "utils/constants";
import {
  removeLocalStorageItemsWithPrefix,
  imageSrcDecoder,
  replaceHTMLEntityAnd,
} from "utils/helperFunctions";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function Inventory() {

  const selectedCountry = useSelector(globalCountry);

  const currentStockId = useSelector(getCurrentStockId);

  const [imageLoading, setImageLoading] = useState(true);

  const [filterProducts, setFilterProducts] = useState([]);

  const [openProductReorderDialog, setOpenProductReorderDialog] = useState(false);

  const [reorderProducts, setReorderProducts] = useState([]);

  const navigate = useHistory();

  const dispatch = useDispatch();

  const {
    data: productList = [],
    isLoading,
    isSuccess,
  } = useGetAllResellerProductsByCountryIdQuery(selectedCountry.id);

  const {
    data: distinctAttributes = [],
    isLoading: isDistinctAttributesLoading,
    isSuccess: isDistinctAttributesSuccess,
  } = useGetDistinctAttributesQuery();

  const {
    data: categoriesList = [],
    isLoading: isCategoriesLoading,
    isSuccess: isCategoriesSuccess,
  } = useGetAllResellerProductCategoriesQuery(selectedCountry.id);

  const [deleteProduct, result] = useDeleteProductMutation();

  const [resetFeaturedProducts] = useResetFeaturedProductsMutation();

  useEffect(() => {
    isLoading || isDistinctAttributesLoading
      ? Loading.dots({ svgColor: "rgba(159, 3, 3, 0.8)" })
      : Loading.remove(500);
  }, [isLoading, isDistinctAttributesLoading]);

  useEffect(() => {
    if (productList && productList.length > 0) {
      Block.dots("#tableWrapper", { svgColor: "rgba(159, 3, 3, 0.8)" });

      let existingReorderProducts = productList.filter((item) => item.priority > 0);

      createProductVariations(productList);
      setReorderProducts(existingReorderProducts);

      Block.remove("#tableWrapper", 2000);
    } else {
      setFilterProducts([]);
    }
  }, [productList, categoriesList, selectedCountry]);

  const createProductVariations = (products) => {
    const productMap = {};

    const sortedProducts = [...products].sort((a, b) => {
      // If parent_id is 0, sort it before others
      if (a.parent_id === 0 && b.parent_id !== 0) {
        return -1;
      } else if (a.parent_id !== 0 && b.parent_id === 0) {
        return 1;
      }
      // Otherwise, sort based on priority (higher priority comes first)
      return b.priority - a.priority;
    });

    // Iterate through the response and create a mapping of parent IDs to products
    sortedProducts.forEach((product) => {
      const productId = product.id;
      const parentId = product.parent_id;

      // Create 'productVariations' property for each product
      const productWithVariations = Object.assign({}, product, { productVariations: [] });

      if (parentId === 0) {
        // If the product has no parent, add it to the main products array
        productMap[productId] = productWithVariations;
      } else {
        // If the product has a parent, add it as a variation to the parent
        if (productMap[parentId] && !productMap[parentId].productVariations) {
          productMap[parentId].productVariations = [];
        }
        if(productMap[parentId]) productMap[parentId].productVariations.push(product);
      }
    });

    Object.values(productMap).forEach((product) => {
      if (product.productVariations.length > 0) {
        const prices = product.productVariations.map((variation) => variation.price);
        const minPrice = Math.min(...prices);
        const maxPrice = Math.max(...prices);
        product.priceRange =
          minPrice == maxPrice ? `${product.price}` : `${minPrice} - ${maxPrice}`;
        product.minPrice = minPrice;
      } else {
        product.priceRange = `${product.price}`;
      }
    });

    let filterMappedProdcuts = Object.values(productMap).filter(
      (product) => !(product.type == "variable" && product.productVariations.length == 0)
    ).sort((a, b) => b.priority - a.priority);

    // Set the products state with the main products array
    if (currentStockId && currentStockId.id) {
      let filteredMappedProducts = [...filterMappedProdcuts];
      let arrayIndex = filteredMappedProducts.findIndex((item) => item.id == currentStockId.id);
      if (arrayIndex > -1) {
        const [element] = filteredMappedProducts.splice(arrayIndex, 1);
        filteredMappedProducts.unshift(element);

        setFilterProducts(filteredMappedProducts);
      } else {
        setFilterProducts(filterMappedProdcuts);

        Notify.failure("Product not found!");
      }
    } else {
      setFilterProducts(filterMappedProdcuts);
    }
  };

  useEffect(() => {
    if (currentStockId.id)
      setTimeout(() => {
        const element = document.getElementById(`productId${currentStockId.id}`);
        if (element) {
          element.click();
        }
      }, 1000);
  }, [filterProducts, currentStockId]);

  const deleteRecord = async (id) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      deleteProduct(id)
        .then((response) => {
          if (response) {
            Notify.success("Succefully deleted!");
          } else {
            Notify.failure("Something went wrong!");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    } finally {
      Loading.remove(500);
    }
  };

  useEffect(() => {
    removeCachedData();
    dispatch(resetStockId());
  }, []);

  const removeCachedData = () => {
    const cacheVariationPrefix = "cacheVariation";
    const shortDescriptionState = "shortDescriptionState";
    const descriptionState = "descriptionState";

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(cacheVariationPrefix)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(shortDescriptionState)) {
        localStorage.removeItem(key);
      }
    });

    Object.keys(localStorage).forEach((key) => {
      if (key.startsWith(descriptionState)) {
        localStorage.removeItem(key);
      }
    });

    localStorage.removeItem('localParentName');
    localStorage.removeItem('localParentCategories');

  };

  const massResetFeaturedProducts = async () => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });
    try {
      resetFeaturedProducts(selectedCountry.id).then((response) => {
        if (response.data?.status == "success") {
          Notify.success("Successfully resetted!");
        } else {
          Notify.failure("Something went wrong!");
        }
      });
    } catch (e) {
      console.log(e);
    } finally {
      Loading.remove(500);
    }
  };

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="dark" />}
        title={<MDTypography color="info">Inventory</MDTypography>}
        action={
          <MDBox display="flex" alignItems="center">
            <MDButton
              variant="gradient"
              color="primary"
              sx={{ mr: 1 }}
              onClick={() => {
                Confirm.show(
                  `Please Confirm`,
                  "Are you sure you want to reset the featured products?",
                  "Yes",
                  "No",
                  () => {
                    massResetFeaturedProducts();
                  },
                  () => {},
                  {}
                );
              }}
            >
              Reset Featured Products
            </MDButton>
            {filterProducts && filterProducts.length > 0 && <ProductReorder
              openProductReorderDialog={openProductReorderDialog}
              setOpenProductReorderDialog={setOpenProductReorderDialog}
              reorderProducts={reorderProducts}
            />}
            <MDButton
              variant="gradient"
              color="info"
              onClick={() => navigate.push(`/user/add-inventory`)}
            >
              Add New
            </MDButton>
          </MDBox>
        }
      />
      <CardContent id="wrapper">
        <br />
        <MDBox id="tableWrapper">
        <DataTable
            id={tables.INVENTORY}
            // headerActions={
            //   //
            // }
            columns={[
              {
                Header: "Image",
                accessor: "image",
                Cell: ({ row: { original } }) => (
                  <MDBox display="flex" alignItems="center" lineHeight={1}>
                    {imageLoading && (
                      <CircularProgress
                        color="primary"
                        sx={{
                          width: "10px",
                          height: "10px",
                        }}
                        size="small"
                      />
                    )}
                    <MDAvatar
                      src={
                        original.products_images &&
                        original.products_images.length > 0 &&
                        original.products_images[0] &&
                        original.products_images[0].src
                          ? imageSrcDecoder(original.products_images[0].src)
                          : noPreview
                      }
                      name={
                        original.products_images &&
                        original.products_images.length > 0 &&
                        original.products_images[0] &&
                        original.products_images[0].name
                      }
                      onLoad={() => setImageLoading(false)}
                      size="md"
                    />
                  </MDBox>
                ),
              },
              {
                Header: "Product Name",
                accessor: "name",
              },
              {
                Header: "Product Type",
                accessor: "type",
                Cell: ({ row: { original } }) => (
                  <span style={{ textTransform: "capitalize" }}>{original.type}</span>
                ),
              },
              // {
              //   Header: "Price",
              //   accessor: "priceRange",
              //   Cell: ({ row: { original } }) => (
              //     <span>${selectedCountry.currency} {original.priceRange}</span>
              //   ),
              // },
              {
                Header: "Stock Status",
                accessor: "is_in_stock",
                Cell: ({ row: { original } }) =>
                  {if(original.type == 'simple') {
                    return original.is_in_stock ? (
                      <>
                        <MDBox>
                          <MDBadge
                            badgeContent="In stock"
                            color="success"
                            variant="gradient"
                            size="sm"
                          />
                        </MDBox>
                      </>
                    ) : (
                      <MDBox>
                        <MDBadge
                          badgeContent="Out of stock"
                          color="error"
                          variant="gradient"
                          size="sm"
                        />
                      </MDBox>
                    )
                  } else {
                    return original.productVariations && original.productVariations.length > 0 && original.productVariations.filter(variation => variation.is_in_stock == true).length > 0 ? (
                      <>
                        <MDBox>
                          <MDBadge
                            badgeContent="In stock"
                            color="success"
                            variant="gradient"
                            size="sm"
                          />
                        </MDBox>
                      </>
                    ) : (
                      <MDBox>
                        <MDBadge
                          badgeContent="Out of stock"
                          color="error"
                          variant="gradient"
                          size="sm"
                        />
                      </MDBox>
                    )
                  }}
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                align: "right",
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    {original.id == currentStockId.id && <div className="highlight-animation" style={{ position: 'absolute', borderRadius: 5, top: 0, right: 0, left: 0, bottom: 0 }}></div>}
                    <AddToReOrderList
                      currentProductId={original.id}
                      currentPrority={original.priority}
                      currentProduct={original}
                      reorderProducts={reorderProducts}
                      setReorderProducts={setReorderProducts}
                    />
                    <EditInventoryDialog
                      elementId={`productId${original.id}`}
                      parentProductData={original}
                      distinctAttributes={distinctAttributes}
                    />
                    {original.type != "variable" && (
                      <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                        <IconButton
                          aria-label="view"
                          size="small"
                          onClick={() => {
                            Confirm.show(
                              `Please Confirm`,
                              "Are you sure you want to delete?",
                              "Yes",
                              "No",
                              () => {
                                deleteRecord(original.id);
                              },
                              () => {},
                              {}
                            );
                          }}
                        >
                          <Close color="error" fontSize="inherit" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </div>
                ),
              },
            ]}
            data={filterProducts || []}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            // actionButton={
            //   <MDButton
            //     variant="gradient"
            //     color="primary"
            //     sx={{ ml: 2 }}
            //     onClick={handleClickOpen}
            //     disabled={urlsByCountryId.length == 0}
            //   >
            //     Add Stock
            //   </MDButton>
            // }
          />
        </MDBox>
      </CardContent>
    </Card>
  );
}

export default Inventory;
