/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import RestartAltIcon from "@mui/icons-material/RestartAlt";

import MenuItem from "@mui/material/MenuItem";

import TextField from "@mui/material/TextField";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";
import { CustomSelect } from "components/CustomSelect";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

//example components
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { useGetAllCompaniesQuery } from "services/companies";

import { globalCountry } from "store/entities/countries";

import {
  useGetAllBDMQuery,
  useDeleteBDMMutation
} from "services/bdm";

import { TableRowsTwoTone } from "@mui/icons-material";

import Alert from "components/CustomAlert";

import CompanyDetails from "./CompanyDetails";
import EditCompany from "./EditCompany";

import { notificationForwardSection, resetForwardSection } from "store/entities/notifications";

import { tables } from 'utils/constants';

function Companies() {
  
  const dispatch = useDispatch();

  const selectedCountry = useSelector(globalCountry);

  const notificationDetails = useSelector(notificationForwardSection);

  const [tableData, setTableData] = useState([]);

  const [selectedBDM, setSelectedBDM] = useState(0);

  const [modifiedBDMArray, setModifiedBDMArray] = useState([]);

  const { data: BDMList = [], isLoading: isBDMLoading, isSuccess: isBDMSuccess } = useGetAllBDMQuery();

  const {
    data: companiesList = [],
    refetch,
    isLoading,
    isSuccess,
  } = useGetAllCompaniesQuery(selectedCountry.id);

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    (isSuccess && isBDMSuccess) && Loading.remove(500);
  }, [isSuccess, isBDMSuccess]);

  useEffect(() => {
    if (companiesList.length > 0) {
      if (Object.keys(notificationDetails).length > 0) {
        setSelectedBDM(0)
        if (notificationDetails.category == "COMPANY") {
          let allCompanies = [...companiesList];
          let arrayIndex = allCompanies.findIndex(item => item.id == notificationDetails.id);
          if(arrayIndex > - 1) {
            const [element] = allCompanies.splice(arrayIndex, 1);
            allCompanies.unshift(element);
            
            setTableData(allCompanies);

            setTimeout(() => {
              const element = document.getElementById(`companyId${notificationDetails.id}`);
              if (element) {
                element.click();
              }
            }, 200); 
          } else {
            const filteredData = companiesList.filter((item) => {
              var res =
              selectedBDM != 0 ? item?.assigned_bdms[0]?.bdms.id == selectedBDM : true;
              // res = res && order.order_status === orderStatus;
              return res;
            });
            setTableData(filteredData);

            Notify.failure("Company not found!");
          }
        }
      } else {
        const filteredData = companiesList.filter((item) => {
          var res =
          selectedBDM != 0 ? item?.assigned_bdms[0]?.bdms.id == selectedBDM : true;
          // res = res && order.order_status === orderStatus;
          return res;
        });
        setTableData(filteredData);
      }
    }
  }, [companiesList, selectedBDM, notificationDetails]);

  useEffect(() => {
    if (tableData.length > 0 && notificationDetails.id) {
      if(tableData[0].id != notificationDetails.id) {
        let allCompanies = [...tableData];
        let arrayIndex = tableData.findIndex(item => item.id == notificationDetails.id);
        if(arrayIndex > - 1) {
          const [element] = allCompanies.splice(arrayIndex, 1);
          allCompanies.unshift(element);
          
          setTableData(allCompanies);
        }
      }
      setTimeout(() => {
        const element = document.getElementById(`companyId${notificationDetails.id}`);
        if (element) {
          element.click();
        }
      }, 1000);
    }
  }, [tableData, notificationDetails]);

  useEffect(() => {
    if (BDMList && BDMList.length > 0) {
      let combinedArray = [...BDMList];
      combinedArray.push({
        'id': 0,
        'email': 'All',
        'first_name': 'All',
        'last_name': '',
        'active': 1,
        'created_date': "",
        'assigned_date': "",
        'total_sales': 0,
      })
      setModifiedBDMArray(combinedArray.sort((a, b) => a.id - b.id));
    }
  }, [BDMList]);

  // useEffect(() => {
  //   // if(notificationDetails)
  //   if (tableData.length > 0 && notificationDetails)
  //     setTimeout(() => {
  //       if (notificationDetails.category == "COMPANY") {
  //         const element = document.getElementById(`companyId${notificationDetails.id}`);
  //         if (element) {
  //           element.click();
  //         }
  //       }
  //     }, 200);
  // }, [notificationDetails, tableData]);

  useEffect(() => {
    return () => {
      dispatch(resetForwardSection());
    };
  }, []);

  return (
    <Card>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Companies</MDTypography>}
        // action={
        //   <MDButton variant="gradient" color="dark">
        //     add
        //   </MDButton>
        // }
      />
      <CardContent>
        {modifiedBDMArray && modifiedBDMArray.length > 0 &&
        <GridContainer>
          <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
            <CustomSelect
              label="Filter by BDM *"
              inputProps={{
                id: "bdm_id",
                name: "bdm_id",
              }}
              value={selectedBDM}
              onChange={(e) => {
                setSelectedBDM(e.target.value);
                dispatch(resetForwardSection());
              }}
              sx={{
                textTransform: 'capitalize',
                "& .MuiInputBase-root .MuiSelect-select": {
                  height: "44px",
                  // color: "#fff",
                  // fontWeight: "400",
                },
                "& .MuiFormLabel-root": {
                  backgroundColor: "#fff",
                  // color: "#e0e1e5",
                  padding: "0 5px",
                },
              }}
              // helperText={errors.country_id}
              // error={errors.country_id}
            >
              <MenuItem disabled>BDMs</MenuItem>
              {modifiedBDMArray.map((prop, key) => (
                <MenuItem sx={{ textTransform: 'capitalize' }} key={key} value={prop.id}>
                  {prop.first_name + ' ' + prop.last_name}
                </MenuItem>
              ))}
            </CustomSelect>
          </GridItem>
        </GridContainer>}
        {companiesList.length > 0 ? (
          <DataTable
            id={tables.COMPANIES}
            headerActions={
              <Tooltip title="Refetch companies" sx={{ ml: 1 }} placement="top">
                <IconButton
                  aria-label="view"
                  size="small"
                  onClick={refetch}
                >
                  <RestartAltIcon color="info" fontSize="inherit" />
                </IconButton>
              </Tooltip>
            }
            columns={[
              {
                Header: "Id",
                accessor: "id",
                width: "10%",
              },
              {
                Header: "Name",
                accessor: "name",
              },
              // {
              //   Header: "Address",
              //   accessor: "address",
              // },
              {
                Header: "Phone",
                accessor: "main_phone",
              },
              {
                Header: "Email",
                accessor: "invoice_email",
              },
              {
                Header: "Status",
                accessor: "status",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                align: "center",
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div>
                    {original.status == "PENDING" ? (
                      <>
                        <MDBox ml={-1}>
                          <MDBadge
                            badgeContent="Pending"
                            color="info"
                            variant="gradient"
                            size="sm"
                          />
                        </MDBox>
                      </>
                    ) : original.status == "ACTIVE" ? (
                      <MDBox ml={-1}>
                        <MDBadge
                          badgeContent="Verified"
                          color="success"
                          variant="gradient"
                          size="sm"
                        />
                      </MDBox>
                    ) : (
                      <MDBox ml={-1}>
                        <MDBadge
                          badgeContent="Rejected"
                          color="error"
                          variant="gradient"
                          size="sm"
                        />
                      </MDBox>
                    )}
                  </div>
                ),
              },
              {
                Header: "Is Credited Customer",
                accessor: "is_credit_customer",
                Cell: ({ row: { original } }) =>
                  original.is_credit_customer ? (
                    <MDBox ml={-1}>
                      <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                    </MDBox>
                  ) : (
                    <MDBox ml={-1}>
                      <MDBadge badgeContent="No" color="dark" variant="gradient" size="sm" />
                    </MDBox>
                  ),
              },
              {
                Header: "Actions",
                accessor: "actions",
                disableSortBy: true,
                disableFilters: true,
                show: true,
                align: "right",
                // eslint-disable-next-line react/display-name
                Cell: ({ row: { original } }) => (
                  <div className="actions-right">
                    {original.id == notificationDetails.id && <div className="highlight-animation" style={{ position: 'absolute', borderRadius: 5, top: 0, right: 0, left: 0, bottom: 0 }}></div>}
                    {/* <Tooltip title="Edit" placement="top">
                      <IconButton aria-label="view" size="small" onClick={() => {}}>
                        <Edit color="warning" fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete" sx={{ ml: 1 }} placement="top">
                      <IconButton aria-label="view" size="small" onClick={() => {}}>
                        <Close color="error" fontSize="inherit" />
                      </IconButton>
                    </Tooltip> */}
                    <CompanyDetails id={original.id} details={original} BDMList={BDMList} />
                    <EditCompany details={original} />
                  </div>
                ),
              },
            ]}
            data={tableData || []}
            isSorted={true}
            entriesPerPage={false}
            showTotalEntries={false}
            hideColumnSelection={false}
            noEndBorder
            canSearch
            // actionButton={
            //   <MDButton variant="gradient" color="primary" sx={{ ml: 2 }} onClick={handleClickOpen}>
            //     Add Product
            //   </MDButton>
            // }
          />
        ) : (
          <Alert severity="warning">No Companies found! <Tooltip title="Refetch companies" sx={{ ml: 1 }} placement="top">
          <IconButton
            aria-label="view"
            size="small"
            onClick={refetch}
          >
            <RestartAltIcon color="info" fontSize="inherit" />
          </IconButton>
        </Tooltip></Alert>
        )}
      </CardContent>
    </Card>
  );
}

export default Companies;
