/* eslint-disable react/prop-types */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

import { Loading } from "notiflix/build/notiflix-loading-aio";
import { Notify } from "notiflix/build/notiflix-notify-aio";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

import TextField from "@mui/material/TextField";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

import Tooltip from "@mui/material/Tooltip";

import Edit from "@mui/icons-material/Edit";
import Close from "@mui/icons-material/Close";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import CustomTabs from "components/CustomTabs";

//example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import RejectNote from "components/RejectNote";

import {
  useGetRequiredFilesForCompanyQuery,
  useVerifyCompanyCreditApplicationMutation,
} from "services/companies";

import { globalCountry } from "store/entities/countries";

import { TableRowsTwoTone } from "@mui/icons-material";

import Alert from "components/CustomAlert";

import DetailBlock from "components/DetailBlock";

import DirectorsDetails from "../DirectorsDetails";
import SuppliersDetails from "../SuppliersDetails";
import UploadCA from "./UploadCA";

function CreditApplication({ details, id }) {
  const selectedCountry = useSelector(globalCountry);

  const [rejectNoteOpen, setRejectNoteOpen] = useState(false);

  const [suspendNoteOpen, setSuspendNoteOpen] = useState(false);

  const [filteredBalanceSheet, setFilteredBalanceSheet] = useState([]);
  const [filteredProfitLoss, setFilteredProfitLoss] = useState([]);

  const {
    data: filesList = [],
    isLoading: isFilesLoading,
    isSuccess: isFilesSuccess,
  } = useGetRequiredFilesForCompanyQuery(id);

  const [changeCreditApplicationStatus, { isLoading: isCompanyVerifing }] =
    useVerifyCompanyCreditApplicationMutation();

  const approveCreditApplicationStatus = (id, status) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    let note = "";

    try {
      changeCreditApplicationStatus({ company_Id: id, status: status, note }).then((response) => {
        // console.log(response);
        if (response.data?.status == "success") {
          Notify.success("Status updated!");
        }
      });
    } catch (e) {
    } finally {
      Loading.remove(500);
    }
  };

  const onRejectSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      changeCreditApplicationStatus({
        company_Id: details.id,
        status: "REJECTED",
        note: data.note,
      }).then((response) => {
        // console.log(response);
        if (response.data?.status == "success") {
          Notify.success("Status updated!");
        }
      });
    } catch (e) {
    } finally {
      setRejectNoteOpen(false);
      Loading.remove(500);
    }
  };

  const onSuspendSubmit = (data) => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    try {
      changeCreditApplicationStatus({
        company_Id: details.id,
        status: "SUSPENDED",
        note: data.note,
      }).then((response) => {
        // console.log(response);
        if (response.data?.status == "success") {
          Notify.success("Status updated!");
        }
      });
    } catch (e) {
    } finally {
      setRejectNoteOpen(false);
      Loading.remove(500);
    }
  };

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    isFilesSuccess && Loading.remove(500);
  }, [isFilesSuccess]);

  useEffect(() => {
    if (filesList.length > 0) {
      let balanceSheets = filesList.filter((item) => item.doc_type == "BALANCESHEET");
      let profitlossSheets = filesList.filter((item) => item.doc_type == "PROFITLOSS");

      let modifiedBalanceSheets = balanceSheets.map((item) => {
        if (item.filepath) {
          let fileExtension = item.filepath.split(".").pop().toLowerCase();
          if (fileExtension == "pdf") {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "pdf",
            };
          } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          } else if (["doc", "docx"].includes(fileExtension)) {
            const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
              process.env.REACT_APP_URL + "/" + filepath
            )}&embedded=true`;
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: googleDocsViewerURL,
              file_type: "doc",
            };
          } else {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          }
        }
      });

      let modifiedProfitlossSheets = profitlossSheets.map((item) => {
        if (item.filepath) {
          let fileExtension = item.filepath.split(".").pop().toLowerCase();
          if (fileExtension == "pdf") {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "pdf",
            };
          } else if (["jpg", "jpeg", "png"].includes(fileExtension)) {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          } else if (["doc", "docx"].includes(fileExtension)) {
            const googleDocsViewerURL = `https://docs.google.com/gview?url=${encodeURIComponent(
              process.env.REACT_APP_URL + "/" + filepath
            )}&embedded=true`;
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: googleDocsViewerURL,
              file_type: "doc",
            };
          } else {
            return {
              id: item.id,
              company_id: item.company_id,
              description: item.description,
              doc_type: item.doc_type,
              filepath: item.filepath,
              file_type: "img",
            };
          }
        }
      });

      if (balanceSheets.length > 0) setFilteredBalanceSheet(modifiedBalanceSheets);
      if (profitlossSheets.length > 0) setFilteredProfitLoss(modifiedProfitlossSheets);
    }
  }, [filesList]);

  return (
    <Card sx={{ mt: 2 }}>
      <CardHeader
        // avatar={<AccountCircleIcon fontSize="large" color="info" />}
        title={<MDTypography color="info">Credit Application</MDTypography>}
        action={
          details.credit_application_status == "PENDING" ? (
            <>
              <MDBox>
                <MDBadge badgeContent="Pending" color="info" variant="gradient" size="sm" />
              </MDBox>
            </>
          ) : details.credit_application_status == "ACTIVE" ? (
            <MDBox>
              <MDBadge badgeContent="Verified" color="success" variant="gradient" size="sm" />
            </MDBox>
          ) : details.credit_application_status == "REJECTED" ? (
            <MDBox>
              {details.credit_note ? (
                <MDBadge badgeContent="Rejected" color="error" variant="gradient" size="sm" />
              ) : (
                <MDBadge badgeContent="Rejected" color="error" variant="gradient" size="sm" />
              )}
            </MDBox>
          ) : (
            <MDBox>
              {details.credit_note ? (
                <MDBadge badgeContent="Suspended" color="warning" variant="gradient" size="sm" />
              ) : (
                <MDBadge badgeContent="Suspended" color="warning" variant="gradient" size="sm" />
              )}
            </MDBox>
          )
        }
      />
      <CardContent>
        <MDBox sx={{ textAlign: "right", mb: 2 }}>
          {details.credit_application_status == "PENDING" ? (
            <>
              <Tooltip title="Approve" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="info"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => approveCreditApplicationStatus(details.id, "ACTIVE")}
                >
                  Approve Credit Application
                </MDButton>
              </Tooltip>
              <Tooltip title="Reject" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => setRejectNoteOpen(true)}
                >
                  Reject Credit Application
                </MDButton>
              </Tooltip>
              <Tooltip title="Suspend" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="warning"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => setSuspendNoteOpen(true)}
                >
                  Mark as Suspend
                </MDButton>
              </Tooltip>
            </>
          ) : details.credit_application_status == "ACTIVE" ? (
            <>
              <Tooltip title="Reject" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => setRejectNoteOpen(true)}
                >
                  Reject Credit Application
                </MDButton>
              </Tooltip>
              <Tooltip title="Suspend" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="warning"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => setSuspendNoteOpen(true)}
                >
                  Mark as Suspend
                </MDButton>
              </Tooltip>
            </>
          ) : details.credit_application_status == "REJECTED" ? (
            <>
              <Tooltip title="Approve" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="info"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => approveCreditApplicationStatus(details.id, "ACTIVE")}
                >
                  Approve Credit Application
                </MDButton>
              </Tooltip>
              <Tooltip title="Suspend" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="warning"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => setSuspendNoteOpen(true)}
                >
                  Mark as Suspend
                </MDButton>
              </Tooltip>
            </>
          ) : (
            <>
              <Tooltip title="Approve" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="info"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => approveCreditApplicationStatus(details.id, "ACTIVE")}
                >
                  Approve Credit Application
                </MDButton>
              </Tooltip>
              <Tooltip title="Mark as Rejected" placement="top">
                <MDButton
                  variant="contained"
                  size="small"
                  color="error"
                  sx={{
                    ml: 1,
                    color: "#fff",
                    fontSize: "10px!important",
                    fontWeight: "400",
                  }}
                  onClick={() => setRejectNoteOpen(true)}
                >
                  Allow user to re-upload (Reject)
                </MDButton>
              </Tooltip>
            </>
          )}
          <>
            <RejectNote
              dialogTitle="Reject Note"
              dialogNote="A note is required in order to reject a company credit application."
              openNote={rejectNoteOpen}
              closeNote={setRejectNoteOpen}
              onRejectSubmit={onRejectSubmit}
              submitButtonTitle="Reject"
            />
            <RejectNote
              dialogTitle="Suspend Note"
              dialogNote="A note is required in order to suspend."
              openNote={suspendNoteOpen}
              closeNote={setSuspendNoteOpen}
              onRejectSubmit={onSuspendSubmit}
              submitButtonTitle="Suspend"
            />
          </>
        </MDBox>
        <MDBox>
          {details.credit_application_status == "REJECTED" && details.credit_note ? (
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                sx={{
                  marginBottom: "15px",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  lineHeight: 1,
                  "& .MuiAlert-icon": {
                    fontSize: "18px",
                    opacity: 1,
                    padding: "4px 0",
                    marginRight: "10px",
                  },
                }}
                severity="error"
              >
                {details.credit_note}
              </Alert>
            </GridItem>
          ) : details.credit_application_status == "SUSPENDED" && details.credit_note ? (
            <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
              <Alert
                sx={{
                  marginBottom: "15px",
                  alignItems: "center",
                  fontSize: "0.9rem",
                  fontWeight: 500,
                  lineHeight: 1,
                  "& .MuiAlert-icon": {
                    fontSize: "20px",
                    opacity: 1,
                    padding: "4px 0",
                    marginRight: "10px",
                  },
                }}
                severity="warning"
              >
                {details.credit_note}
              </Alert>
            </GridItem>
          ) : (
            ""
          )}
          <GridContainer>
            {details.is_public_company ? (
              <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                <DetailBlock
                  blockTitle="Is public company?"
                  blockDesc={
                    details.is_public_company ? (
                      <MDBadge badgeContent="Yes" color="success" variant="gradient" size="sm" />
                    ) : (
                      <MDBadge badgeContent="No" color="warning" variant="gradient" size="sm" />
                    )
                  }
                />
              </GridItem>
            ) : (
              ""
            )}
          </GridContainer>
          {filesList && filesList.length > 0 ? (
            <MDBox>
              <CustomTabs
                headerColor="primary"
                tabs={[
                  filteredBalanceSheet.length && {
                    tabName: "Balance Sheet",
                    tabContent: <BalanceSheet sheets={filteredBalanceSheet} />,
                  },
                  filteredProfitLoss.length && {
                    tabName: "Profit and Loss Sheet",
                    tabContent: <ProfitLoss sheets={filteredProfitLoss} />,
                  },
                ]}
              />
            </MDBox>
          ) : (
            <Alert severity="warning">No details found!</Alert>
          )}
        </MDBox>
        <MDBox mt={2}>
          <DirectorsDetails company={details} />
          {details.is_credit_customer != 0 && <SuppliersDetails company={details} />}
          <UploadCA companyId={id} companyName={details.name} filesList={filesList} />
        </MDBox>
      </CardContent>
    </Card>
  );
}

const BalanceSheet = ({ sheets }) => {
  return (
    <Card sx={{ mt: 2 }}>
      {/* <CardHeader
                title={<MDTypography color="info">Balance Sheet</MDTypography>}
            /> */}
      <CardContent>
        <MDBox>
          {sheets.length > 0 ? (
            sheets.map((item, key) => {
              if (item.file_type == "pdf") {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="iframe"
                    src={process.env.REACT_APP_URL + "/" + item.filepath}
                    sx={{ width: "100%", height: 700 }}
                  />
                );
                // <MDBox key={key} mb={2} component="img" src={'http://154.41.251.126:3000/' + item.filepath} sx={{ width: '100%' }} />
                // <MDBox key={key} mb={2} component="img" src={process.env.REACT_APP_URL + '/' + item.filepath} fullWidth />
              } else if (item.file_type == "img") {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="img"
                    src={process.env.REACT_APP_URL + "/" + item.filepath}
                    sx={{ width: "100%" }}
                  />
                );
              } else if (item.file_type == "doc") {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="iframe"
                    src={googleDocsViewerURL}
                    sx={{ width: "100%", height: 700 }}
                  />
                );
              } else {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="img"
                    src={process.env.REACT_APP_URL + "/" + item.filepath}
                    sx={{ width: "100%" }}
                  />
                );
              }
            })
          ) : (
            <Alert severity="warning">no files found!</Alert>
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
};

const ProfitLoss = ({ sheets }) => {
  return (
    <Card sx={{ mt: 2 }}>
      {/* <CardHeader
                title={<MDTypography color="info">Profit and Loss Sheet</MDTypography>}
            /> */}
      <CardContent>
        <MDBox>
          {sheets.length > 0 ? (
            sheets.map((item, key) => {
              if (item.file_type == "pdf") {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="iframe"
                    src={process.env.REACT_APP_URL + "/" + item.filepath}
                    sx={{ width: "100%", height: 700 }}
                  />
                );
                // <MDBox key={key} mb={2} component="img" src={'http://154.41.251.126:3000/' + item.filepath} sx={{ width: '100%' }} />
                // <MDBox key={key} mb={2} component="img" src={process.env.REACT_APP_URL + '/' + item.filepath} fullWidth />
              } else if (item.file_type == "img") {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="img"
                    src={process.env.REACT_APP_URL + "/" + item.filepath}
                    sx={{ width: "100%" }}
                  />
                );
              } else if (item.file_type == "doc") {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="iframe"
                    src={googleDocsViewerURL}
                    sx={{ width: "100%", height: 700 }}
                  />
                );
              } else {
                return (
                  <MDBox
                    key={key}
                    mb={2}
                    component="img"
                    src={process.env.REACT_APP_URL + "/" + item.filepath}
                    sx={{ width: "100%" }}
                  />
                );
              }
            })
          ) : (
            <Alert severity="warning">no files found!</Alert>
          )}
        </MDBox>
      </CardContent>
    </Card>
  );
};

export default CreditApplication;
