import { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

import { Loading } from "notiflix/build/notiflix-loading-aio";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

import Badge from '@mui/material/Badge';

import { useTheme } from "@mui/system";
import { styled } from "@mui/material/styles";

import { CustomSelect } from "components/CustomSelect";

import {
  setSelectedCountry,
  globalCountry,
  selectedCountryURL,
  setSelectedCountryURL,
  resetSelectedCountryURL,
} from "store/entities/countries";

//components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

//example components
import SidenavCollapse from "examples/Sidenav/SidenavCollapse";

// Custom styles for the Sidenav
import SidenavRoot from "examples/Sidenav/SidenavRoot";
import sidenavLogoLabel from "examples/Sidenav/styles/sidenav";

//context
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import { actions as tableActions } from "store/ui/table";

// rtk queries
import { useGetAllCountriesQuery, useLazyGetAllUrlsByCountryIdQuery } from "services/countries";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
    left: "240px!important",
    transform: "translateY(-50%)!important",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
      "& a": {
        textDecoration: "none",
      },
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: "#ccc",
      },
    },
  },
}));

function Sidenav({ color, brand, brandName, routes, ...rest }) {
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode, sidenavColor } = controller;
  const location = useLocation();
  const collapseName = location.pathname.replace("/", "");

  const [zohoError, setZohoError] = useState(false);

  const [openCountryDialog, setOpenCountryDialog] = useState(false);

  const dispatchStore = useDispatch();

  const globalSelectedCountry = useSelector(globalCountry);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const selectedURL = useSelector(selectedCountryURL);

  const { data: countriesList = [], isLoading, isSuccess } = useGetAllCountriesQuery();

  const [
    triggerUrlsByCountryId,
    { data: urlsByCountryId = [], isLoading: isLoadingUrls, isSuccess: isSuccessUrls },
  ] = useLazyGetAllUrlsByCountryIdQuery();

  const handleSetSelectedCountry = (para) => {
    dispatchStore(
      setSelectedCountry({
        id: para.id,
        name: para.name,
        phone_code: para.phone_code,
        symbol: para.symbol,
        currency: para.currency,
        invoiceNinjaAPI: para.invoice_ninja_api,
        isZohoEnabled: para.is_zoho_enabled,
        zohoRefreshToken: para.zoho_refresh_token,
        zohoAccessToken: para.zoho_access_token,
        zohoRefundAccountId: para.zoho_refund_account_id,
      })
    );
    localStorage.removeItem("woocommerceProducts");
    dispatchStore(resetSelectedCountryURL());
  };

  const handleChange = (id) => {
    let selected = countriesList.filter((country) => country.id == id)[0];
    dispatchStore(tableActions.reset());
    handleSetSelectedCountry(selected);
    // localStorage.removeItem("woocommerceProducts");
  };

  useEffect(() => {
    if (globalSelectedCountry.invoiceNinjaAPI == "") {
      setOpenCountryDialog(true);
    } else {
      setOpenCountryDialog(false);
    }
  }, [globalSelectedCountry]);

  useEffect(() => {
    if ((globalSelectedCountry.isZohoEnabled && !globalSelectedCountry.zohoRefundAccountId)) {
      setZohoError(true);
    } else {
      setZohoError(false);
    }
  }, [routes]);

  // useEffect(() => {
  //   // if (globalSelectedCountry.id) triggerUrlsByCountryId(globalSelectedCountry.id);
  //   if (globalSelectedCountry.id) {
  //     resetSelectedCountryURL();
  //     localStorage.removeItem("woocommerceProducts");
  //   }
  // }, [globalSelectedCountry]);

  // useEffect(() => {
  //   if (isSuccessUrls)
  //     if (urlsByCountryId.urls.length > 0)
  //     dispatchStore(
  //         setSelectedCountryURL({
  //           id: urlsByCountryId.urls[0].id,
  //           url: urlsByCountryId.urls[0].url,
  //           c_key: urlsByCountryId.urls[0].cunsumer_key,
  //           c_secret: urlsByCountryId.urls[0].cunsumer_secret,
  //         })
  //       );
  // }, [isSuccessUrls, urlsByCountryId]);

  let textColor = "white";

  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav);
      setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav);
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  useEffect(() => {
    Loading.dots({
      svgColor: "rgba(159, 3, 3, 0.8)",
    });

    (!isLoading || !isLoadingUrls) && Loading.remove(500);

    // if (isSuccess) console.log(countriesList);
  }, [isLoading, isLoadingUrls]);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((routeItem) => {
    let returnValue;

    // console.log(routeItem.childrens)

    if (routeItem.visible === true) {
      if (routeItem.type === "collapse") {
        returnValue = routeItem.href ? (
          <Link
            href={routeItem.href}
            key={routeItem.key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavCollapse
              name={routeItem.name}
              icon={routeItem.icon}
              active={routeItem.key === routeItem.collapseName}
              noCollapse={routeItem.noCollapse}
            />
            {/* {routeItem.childrens && routeItem.childrens.length > 0 && routeItem.childrens.map((child) => (
                <NavLink key={routeItem.key} to={routeItem.route}>
                  <SidenavCollapse
                  name={child.name}
                  icon={child.icon}
                  active={child.key === child.collapseName}
                  noCollapse={child.noCollapse}
                />
                  <MenuItem>{child.name}</MenuItem>
                </NavLink>
              ))} */}
          </Link>
        ) : routeItem.children && routeItem.children.length > 0 ? (
          <>
            <SidenavCollapse
              id={`customized-menu-${routeItem.key}`}
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              name={routeItem.name}
              icon={routeItem.icon}
              active={routeItem.key === routeItem.collapseName}
              onClick={handleClick}
            />
            <StyledMenu
              id={`customized-menu-${routeItem.key}`}
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              {!routeItem.nestedChild && <NavLink key={routeItem.key} to={routeItem.route}>
                <MenuItem onClick={handleClose} disableRipple>
                  {routeItem.icon} {routeItem.name}
                </MenuItem>
              </NavLink>}
              {routeItem.children.map((child) => (
                <NavLink key={child.key} to={child.route}>
                  <MenuItem onClick={handleClose} disableRipple>
                    {child.icon} {child.name}
                  </MenuItem>
                </NavLink>
              ))}
            </StyledMenu>
          </>
        ) : (
        //   routeItem.key == 'zoho' ? globalSelectedCountry.isZohoEnabled ? zohoError ? <NavLink key={routeItem.key} to={routeItem.route}>
        //   <Badge badgeContent={<PriorityHighIcon />} sx={{ width: '100%', '& .MuiBadge-badge': { top: '50%', right: '40px' }}} color="error">
        //     <SidenavCollapse
        //       name={routeItem.name}
        //       icon={routeItem.icon}
        //       active={routeItem.key === routeItem.collapseName}
        //     />
        //   </Badge>
        // </NavLink> : <NavLink key={routeItem.key} to={routeItem.route}>
        //   <SidenavCollapse
        //     name={routeItem.name}
        //     icon={routeItem.icon}
        //     active={routeItem.key === routeItem.collapseName}
        //   />
        // </NavLink> : <></> : <NavLink key={routeItem.key} to={routeItem.route}>
        //   <SidenavCollapse
        //     name={routeItem.name}
        //     icon={routeItem.icon}
        //     active={routeItem.key === routeItem.collapseName}
        //   />
        // </NavLink>
        <NavLink key={routeItem.key} to={routeItem.route}>
            <SidenavCollapse
              name={routeItem.name}
              icon={routeItem.icon}
              active={routeItem.key === routeItem.collapseName}
            />
          </NavLink>
        )
      } else if (routeItem.type === "title") {
        returnValue = (
          <MDTypography
            key={routeItem.key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {routeItem.title}
          </MDTypography>
        );
      } else if (routeItem.type === "divider") {
        returnValue = (
          <Divider
            key={routeItem.key}
            light={
              (!darkMode && !whiteSidenav && !transparentSidenav) ||
              (darkMode && !transparentSidenav && whiteSidenav)
            }
          />
        );
      }
    }
    return returnValue;
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={3} pb={1} px={4} textAlign="center">
        <MDBox
          display={{ xs: "block", xl: "none" }}
          position="absolute"
          top={0}
          right={0}
          p={1.625}
          onClick={closeSidenav}
          sx={{ cursor: "pointer" }}
        >
          <MDTypography variant="h6" color="secondary">
            <Icon sx={{ fontWeight: "bold" }}>close</Icon>
          </MDTypography>
        </MDBox>
        <MDBox component="div">
          <CustomSelect
            label="Country *"
            inputProps={{
              id: "country_id",
              name: "country_id",
            }}
            value={globalSelectedCountry.id}
            onChange={(e) => handleChange(e.target.value)}
            sx={{
              mb: 0,
              "& .MuiInputBase-root .MuiSelect-select": {
                height: "44px",
                color: "#fff",
                fontWeight: "400",
              },
              "& .MuiFormLabel-root": {
                backgroundColor: "#3f3f3f",
                color: "#e0e1e5",
                padding: "0 5px",
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: "#fff",
              },
              "& .MuiInputBase-root fieldset": {
                borderColor: "#fff!important",
              },
            }}
            // helperText={errors.country_id}
            // error={errors.country_id}
          >
            <MenuItem disabled>Countries</MenuItem>
            {countriesList.map((prop, key) => (
              <MenuItem key={key} value={prop.id}>
                {prop.name} ({prop.currency})
              </MenuItem>
            ))}
          </CustomSelect>
        </MDBox>
      </MDBox>
      <Divider
        light={
          (!darkMode && !whiteSidenav && !transparentSidenav) ||
          (darkMode && !transparentSidenav && whiteSidenav)
        }
      />
      <List>{renderRoutes}</List>
      <MDBox p={2} mt="auto">
        {/* <MDButton
          component="a"
          href=""
          target="_blank"
          rel="noreferrer"
          variant="gradient"
          color={sidenavColor}
          fullWidth
        >
          bottom
        </MDButton> */}
      </MDBox>
      <BootstrapDialog
        aria-labelledby="customized-dialog-title"
        maxWidth="lg"
        sx={{
          "& .MuiPaper-root": {
            width: "550px",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "rgba(0, 0, 0, 0.95)",
          },
        }}
        open={openCountryDialog}
      >
        <DialogTitle
          sx={{ m: 0, p: 2, color: "#4b4b4f", fontWeight: 500, fontSize: "1rem" }}
          id="customized-dialog-title"
        >
          You must select a country to proceed
        </DialogTitle>
        <DialogContent dividers>
          <MDBox component="div">
            <CustomSelect
              label="Country *"
              inputProps={{
                id: "country_id",
                name: "country_id",
              }}
              value={globalSelectedCountry.id}
              onChange={(e) => handleChange(e.target.value)}
              sx={{
                mb: 0,
                "& .MuiInputBase-root .MuiSelect-select": {
                  height: "44px",
                  color: "#fff",
                  fontWeight: "400",
                },
                "& .MuiFormLabel-root": {
                  backgroundColor: "#fff",
                  color: "#4b4b4f",
                  padding: "0 5px",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "#4b4b4f",
                },
                "& .MuiInputBase-root fieldset": {
                  borderColor: "#d2d6da!important",
                },
              }}
              // helperText={errors.country_id}
              // error={errors.country_id}
            >
              <MenuItem disabled>Select Country</MenuItem>
              {countriesList.map((prop, key) => (
                <MenuItem key={key} value={prop.id}>
                  {prop.name} ({prop.currency})
                </MenuItem>
              ))}
            </CustomSelect>
          </MDBox>
          <br />
        </DialogContent>
      </BootstrapDialog>
    </SidenavRoot>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
